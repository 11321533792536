/* eslint-disable object-shorthand */
/* eslint-disable no-empty */
/* eslint-disable no-unneeded-ternary */
import "./style.css";
import { CloseOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDDatePicker from "components/Basics/MDDatePicker";
import DatePresets from "components/Custom/DatePresets";
import { useMaterialUIController } from "context";
import i18n from "i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setFilter } from "redux-react/reducers/filtersReducers";
import Languages from "flatpickr/dist/l10n/index";

/**
 * Button to delete date in date picker
 */
export const ButtonClearFilter = ({ updateDate, darkMode }) => {
	return (
		<IconButton
			className={`clearFilter clearFilter_${darkMode ? "dark" : "light"}`}
			size="medium"
			onClick={() => {
				updateDate(null);
			}}
		>
			<CloseOutlined />
		</IconButton>
	);
};
/**
 * Main component
 */
export default function DateFilter({ assistantID, page, storage, tooltip, attribute, title }) {
	const DefaultLanguage = Languages[i18n.language] || Languages.en;

	const dispatch = useDispatch();
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	const [startDate, setStartDateValue] = useState(storage?.$gte ? new Date(storage?.$gte) : null);
	const [endDate, setEndDateValue] = useState(storage?.$lte ? new Date(storage?.$lte) : null);

	const dispatchFilter = ({ startDate, endDate, code = null, mode = "manual" }) => {
		let datePack = {};

		if (startDate) {
			datePack.$gte = startDate;
		}

		if (endDate) {
			datePack.$lte = endDate;
		}

		dispatch(
			setFilter({
				// Version
				version: "2.0",
				assistantID,
				page,
				attribute,
				// front: datePack,
				type: "datetime",
				mode: mode,
				code: code,
				values: datePack
				// name: attribute
				// front: {
				// 	$gte: startDate ?? new Date(0),
				// 	$lte: endDate ?? new Date()
				// },
				// back: {
				// 	name: attribute,
				// 	// value: {
				// 	// 	$gte: startDate ?? new Date(0),
				// 	// 	$lte: endDate ?? new Date()
				// 	// },
				// 	value: datePack,
				// 	type: "datetime"
				// }
			})
		);
	};

	const setStartDate = value => {
		setStartDateValue(value);
		dispatchFilter({ startDate: value, endDate });
	};

	const setEndDate = value => {
		setEndDateValue(value);
		dispatchFilter({ startDate, endDate: value });
	};

	const deleteStartOrEnd = time => {
		if (time === "start") {
			setStartDateValue(null);
			dispatchFilter({ startDate: null, endDate });
		} else if (time === "end") {
			setEndDateValue(null);
			dispatchFilter({ startDate, endDate: null });
		}
	};

	const setDateInterval = (interval, code) => {
		setStartDateValue(interval.$gte);
		setEndDateValue(interval.$lte);
		dispatchFilter({ startDate: interval.$gte, endDate: interval.$lte, code: code, mode: "auto" });
	};
	return (
		<MDBox display="flex">
			<Tooltip placement="top" title={tooltip ? `${title} (${i18n.t("FILTERS.date.start")})` : ""}>
				<MDBox className="datePickerContainer" mr={1}>
					<MDDatePicker
						value={startDate && startDate.getFullYear() === 1970 ? null : startDate}
						options={{
							time_24hr: true,
							enableTime: true,
							dateFormat: "d M Y H:i",
							defaultHour: 0,
							defaultMinute: 0,
							locale: {
								...DefaultLanguage,
								firstDayOfWeek: 1
							}
						}}
						onChange={date => {
							setStartDate(new Date(date));
						}}
						input={{
							className: "date_input_" + (darkMode ? "dark" : "light"),
							placeholder: `${title} (${i18n.t("FILTERS.date.start")})`
						}}
						// Change the background color of today's date
						onDayCreate={(dObj, dStr, fp, dayElem) => {
							if (dayElem.dateObj.toDateString() === new Date().toDateString()) {
								dayElem.className += " today-date";
							}

							if (startDate) {
								let startDateDay = new Date(+startDate).toDateString();
								if (dayElem.dateObj.toDateString() === startDateDay) {
									dayElem.className += " start-date";
								}
							}

							if (endDate) {
								let endDateDay = new Date(+endDate).toDateString();
								if (dayElem.dateObj.toDateString() === endDateDay) {
									dayElem.className += " end-date second-selector-color";
								}
							}
						}}
					/>
					<ButtonClearFilter updateDate={() => deleteStartOrEnd("start")} />
				</MDBox>
			</Tooltip>

			<Tooltip placement="top" title={tooltip ? `${title} (${i18n.t("FILTERS.date.end")})` : ""}>
				<MDBox className="datePickerContainer" mr={1}>
					<MDDatePicker
						value={endDate}
						// date picker options
						options={{
							time_24hr: true,
							enableTime: true,
							dateFormat: "d M Y H:i",
							defaultHour: 23,
							defaultMinute: 59,
							locale: {
								...DefaultLanguage,
								firstDayOfWeek: 1
							}
						}}
						// change handler
						onChange={date => {
							setEndDate(new Date(date));
						}}
						// custom input
						input={{
							className: "date_input_" + (darkMode ? "dark" : "light"),
							placeholder: `${title} (${i18n.t("FILTERS.date.end")})`
						}}
						// Change the background color of today's date
						onDayCreate={(dObj, dStr, fp, dayElem) => {
							if (dayElem.dateObj.toDateString() === new Date().toDateString()) {
								dayElem.className += " today-date";
							}

							if (startDate) {
								let startDateDay = new Date(+startDate).toDateString();
								if (dayElem.dateObj.toDateString() === startDateDay) {
									dayElem.className += " start-date second-selector-color";
								}
							}

							if (endDate) {
								let endDateDay = new Date(+endDate).toDateString();
								if (dayElem.dateObj.toDateString() === endDateDay) {
									dayElem.className += " end-date";
								}
							}
						}}
					/>
					<ButtonClearFilter updateDate={() => deleteStartOrEnd("end")} />
				</MDBox>
			</Tooltip>
			<DatePresets
				fullHeight
				handleSelectPreset={({ interval, code }) => {
					setDateInterval(interval, code);
				}}
			/>
		</MDBox>
	);
}
