/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import axios from "axios";
import store from "redux-react/store";

/**
 * Get a signed URL to upload a file in S3
 * @param {*} file - File to upload
 * @param {*} extra - Extra data to send to the backend
 * @param {*} context - Context to send to the backend, usually the path where the file will be stored
 * @returns
 */
export async function getSignedUrl(file, extra = {}, context = null) {
	try {
		const endPoint = process.env.REACT_APP_AMBACK;

		let user = store.getState().user;
		let assistantID = store.getState().profile.assistantID;

		const axiosCall = await axios(endPoint + "/api/v1/s3/uploadSignedUrl", {
			headers: {
				Authorization: `Bearer ${user.token}`,
				UserEmail: user.email
			},
			method: "POST",
			data: {
				assistantID,
				size: file.size,
				fileName: file.name,
				contentType: file.type,
				...extra,
				context
			}
		});

		return {
			success: true,
			result: axiosCall.data
		};
	} catch (error) {
		return {
			success: false,
			error
		};
	}
}

/**
 * Upload a file in S3, need a signed URL
 * @param {*} signedURL
 * @param {*} file
 * @returns
 */
export async function uploadToSignedUrl(signedURL, file) {
	try {
		await axios.put(signedURL, file, {
			headers: {
				"Content-Type": file.type
			}
		});
		return {
			success: true
		};
	} catch (error) {
		return {
			success: false,
			error
		};
	}
}

/**
 * Delete a file from S3 : two ways :
 * - By key : key is the complete path to the file in the bucket
 * - By path and fileName : path is the folder where the file is stored, fileName is the name of the file
 *
 * Prefer using delete by key.
 *
 * @param {*} param0
 * @returns
 */
export async function deleteFileFromPath({ key, path, fileName }) {
	try {
		const endPoint = process.env.REACT_APP_AMBACK;

		let user = store.getState().user;
		let assistantID = store.getState().profile.assistantID;

		const axiosCall = await axios(endPoint + "/api/v1/s3/deleteFile", {
			headers: {
				Authorization: `Bearer ${user.token}`,
				UserEmail: user.email
			},
			method: "POST",
			data: {
				assistantID,
				// Key infos
				key,
				// Path infos
				path,
				fileName
			}
		});

		return {
			success: true,
			result: axiosCall.data
		};
	} catch (error) {
		return {
			success: false,
			error
		};
	}
}
