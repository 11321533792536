import { Fade, Grow } from "@mui/material";
import { useState, useEffect } from "react";
import i18n from "i18n";
import { useDispatch } from "react-redux";
import FormActions from "redux-react/actions/formAction";
import lod_ from "lodash";

import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import C from "../constants";
import InformationCardAssistant from "../components/InformationCardAssistant";

/**
 * Step 1: Selected the profile type
 */
const SkillEditorStepEditSkill = ({ skill, handleTypeSkillFields, validStep }) => {
	const dispatch = useDispatch();
	// Handle completing skill fields
	const handleChange = (path, value) => {
		let copySkill = lod_.cloneDeep(skill);
		copySkill = lod_.set(copySkill, path, value);
		handleTypeSkillFields(copySkill);

		validStep(
			!lod_.isNil(copySkill?.name) &&
				!lod_.isEqual(copySkill?.name, "") &&
				!lod_.isNil(copySkill?.description) &&
				!lod_.isEqual(copySkill?.description, "")
		);
	};
	//  const [skillBeingCreated, setskillBeingCreated] = useState([]);

	useEffect(() => {
		if (skill?.name && skill?.description) {
			validStep();
		}
	}, []);

	return (
		<MDBox>
			<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
				<MDBox mt={2} width="50%">
					<MDBox mt={2}>
						<MDInput
							variant="outlined"
							label={
								<span>
									{i18n.t("SETTINGS.SKILLS.skillNameField")}
									<span className="mandatoryField">*</span>
								</span>
							}
							value={skill?.name || ""}
							onChange={e => handleChange("name", e.target.value)}
						/>
					</MDBox>
				</MDBox>
				<MDBox mt={2} width="50%">
					<MDBox mt={2}>
						<MDInput
							variant="outlined"
							label={
								<span>
									{i18n.t("SETTINGS.SKILLS.skillDescriptionField")}
									<span className="mandatoryField">*</span>
								</span>
							}
							value={skill?.description || ""}
							onChange={e => handleChange("description", e.target.value)}
						/>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default SkillEditorStepEditSkill;
