/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function configs(labels, datasets, tab = null) {
	function getData(datasets) {
		if (datasets?.length < 2 || !tab) return datasets;

		let newDataset = [
			{
				...datasets[0],
				data: []
			},
			{
				...datasets[1],
				data: []
			}
		];

		for (let i = 0; i < datasets[0].data.length; i++) {
			let first = datasets[0].data[i];
			let second = datasets[1].data[i];

			let sum = first + second;

			newDataset[0].data[i] = tab === 0 ? first : ((first / sum) * 100).toFixed(2);
			newDataset[1].data[i] = tab === 0 ? second : ((second / sum) * 100).toFixed(2);
		}

		return newDataset;
	}

	return {
		data: {
			labels,
			datasets: getData(datasets)
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					callbacks: {
						label: tooltipItem => {
							const value = tooltipItem.formattedValue;
							const label = tooltipItem.dataset.label;

							let tooltipValue = value;

							// 1- Display label
							if (label) {
								if (label.trim() === "%") {
									tooltipValue = `${tooltipValue}${label}`;
								} else {
									tooltipValue = `${tooltipValue} ${label}`;
								}
							}

							// 2- Display raw value
							if (tooltipItem.dataset?.valueAfterPercentage) {
								let defaultData = tooltipItem.dataset.defaultData ?? [];
								let defaultValue = defaultData[tooltipItem.dataIndex];

								if (defaultValue) {
									let total = defaultValue.total;

									if (total !== undefined && total !== null) {
										tooltipValue = `${tooltipValue} (${total})`;
									}
								}
							}

							return tooltipValue;
						}
					}
				}
			},
			scales: {
				y: {
					grid: {
						drawBorder: false,
						display: true,
						drawOnChartArea: true,
						drawTicks: false,
						borderDash: [5, 5]
					},
					ticks: {
						display: true,
						padding: 10,
						color: "#9ca2b7",
						font: {
							size: 11,
							family: typography.fontFamily,
							style: "normal",
							lineHeight: 2
						}
					}
				},
				x: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: true,
						drawTicks: true
					},
					ticks: {
						display: true,
						color: "#9ca2b7",
						padding: 10,
						font: {
							size: 11,
							family: typography.fontFamily,
							style: "normal",
							lineHeight: 2
						}
					}
				}
			}
		}
	};
}

export default configs;
