import axios from "axios";
// import { clear, coldError, errorMsg } from "../reducers/snackMsgsReducers";
import i18n from "i18n";
import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";

function validateArgument(argument, backArgument, nullable) {
	if (argument === backArgument) {
		return true;
	}

	if (!argument && nullable) {
		return true;
	}

	return false;
}

const apiMiddleware =
	({ dispatch, getState }) =>
	next =>
	action => {
		if (!action) {
			return;
		}
		if (Array.isArray(action)) {
			action.forEach(obj => obj && dispatch(obj));
			return;
		}
		next(action);

		if (!action?.payload?.requestUrl) return;

		let {
			isCustomUrl,
			requestUrl: url,
			method,
			schema,
			data,
			onLoading,
			onSuccess,
			onFailure,
			headers,
			withCredentials
		} = action.payload;
		const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

		// axios default configs
		axios.defaults.baseURL = process.env.REACT_APP_AMBACK || "";
		axios.defaults.headers.common["Content-Type"] = "application/json";

		// dispatch(clear());

		const { user, profile } = getState();

		if (user && !headers && !isCustomUrl) {
			headers = {
				Authorization: `Bearer ${user.token}`
			};
		}

		/**
		 * Set assistantID in headers to verify response
		 */
		headers.AssistantID = profile.assistantID;
		headers.language = profile?.language || user?.language || "FR";

		if (onLoading) {
			dispatch(onLoading(true));
		}
		axios
			.request({
				url,
				method,
				headers,
				[dataOrParams]: data,
				withCredentials
			})
			.then(({ data }) => {
				/**
				 * Verify response format, must be :
				 *
				 * {
				 *  header: {},
				 *  payload: {}
				 * }
				 */
				if (data.header && data.payload) {
					/**
					 * Verify response header
					 * - userEmail is the same
					 * - assistantID is the same
					 * - application is the same
					 */
					let isUser = validateArgument(user.userID, data.header.userEmail);
					let isAssistantID = validateArgument(profile.assistantID, data.header.assistantID, true);
					let isApplication = validateArgument(
						process.env.REACT_APP_APPNAME,
						data.header.application
					);
					if (isUser && isAssistantID && isApplication) {
						dispatch(onSuccess(data.payload));
					} else {
						dispatch(
							display({
								message: "Request received unauthorized",
								type: "error"
							})
						);
					}
				} else {
					dispatch(
						display({
							message: "Request received bad format",
							type: "error"
						})
					);
				}
			})
			.catch(error => {
				if (typeof onFailure === "function") {
					if (error.message === "Network Error") {
						dispatch(setError("networkError"));
					} else {
						dispatch(onFailure(error));
					}
				}
			})
			.then(() => {
				if (onLoading) {
					dispatch(onLoading(false));
				}
			});
	};

export default apiMiddleware;
