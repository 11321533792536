/* eslint-disable no-plusplus */

import { Chip, Switch, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
import MDInput from "components/Basics/MDInput";
import { Add, Delete } from "@mui/icons-material";

import i18n from "i18n";
import lod_ from "lodash";
import SettingsActions from "redux-react/actions/settingsActions";
import { selectUser } from "redux-react/reducers/userReducers";

const FormRule = ({ submit, dictionary = {} }) => {
	const dispatch = useDispatch();
	const [name, setName] = useState("");
	const [code, setCode] = useState("");
	const [desc, setDesc] = useState("");
	const [system, setSystem] = useState(false);
	const [selectedDictionnary, setSelectedDictionnary] = useState([]);
	const [showWarning, setShowWarning] = useState(false);
	const user = useSelector(selectUser);

	const [dictionariesList, setDictionariesList] = useState([]);

	useEffect(() => {
		if (selectedDictionnary.length > 0 && name.trim() !== "" && code.trim() !== "") {
			submit({
				valid: true,
				data: {
					name,
					code,
					desc,
					system,
					inputDictionary: selectedDictionnary
				}
			});
		} else {
			submit({
				valid: false
			});
		}
	}, [name, code, selectedDictionnary]);

	const selectDictionary = codeDictionary => {
		if (!selectedDictionnary.includes(codeDictionary)) {
			// add
			let copySelectedD = lod_.cloneDeep(selectedDictionnary);
			copySelectedD.push(codeDictionary);
			setSelectedDictionnary(copySelectedD);
		} else {
			// remove
			let copySelectedD = lod_.cloneDeep(selectedDictionnary);
			copySelectedD = copySelectedD.filter(element => element !== codeDictionary);
			setSelectedDictionnary(copySelectedD);
		}
	};

	const checkCodeItem = text => {
		let regex = /^[A-Za-z]+$/;
		if (regex.test(text)) {
			setCode(text);
		} else if (text === "") {
			setCode(text);
		} else {
			setShowWarning(true);
			setTimeout(() => {
				setShowWarning(false);
			}, 3000);
		}
	};

	useEffect(() => {
		// Load possible dictionaries
		dispatch(
			SettingsActions.getDictionariesMappings(res => {
				setDictionariesList(res.list);
			})
		);
	}, []);

	return (
		<MDBox>
			<MDTypography variant="h6">{i18n.t("SETTINGS.RULES.newRule")}</MDTypography>
			<MDBox display="flex" flexDirection="column" mt={1}>
				<MDBox>
					<MDInput
						className="dialogInput"
						value={name}
						onChange={e => setName(e.target.value)}
						label={
							<span>
								{i18n.t("SETTINGS.RULES.ruleName")}
								<span className="mandatoryField">*</span>
							</span>
						}
					/>
				</MDBox>
				<MDBox mt={1}>
					<Tooltip
						open={showWarning}
						title={i18n.t("SETTINGS.RULES.regexCode")}
						placement="right"
						disableFocusListener
						disableHoverListener
						disableTouchListener
					>
						<MDInput
							className="dialogInput"
							value={code}
							onChange={e => checkCodeItem(e.target.value)}
							label={
								<span>
									{i18n.t("SETTINGS.RULES.ruleCode")}
									<span className="mandatoryField">*</span>
								</span>
							}
						/>
					</Tooltip>
				</MDBox>
				<MDBox mt={1}>
					<MDInput
						className="dialogInput"
						value={desc}
						onChange={e => setDesc(e.target.value)}
						label={<span>{i18n.t("SETTINGS.RULES.ruleDesc")}</span>}
					/>
				</MDBox>

				{user.system && (
					<MDBox
						mt={1}
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						className="customSwitchContainer"
						onClick={() => setSystem(!system)}
					>
						{i18n.t("SETTINGS.RULES.ruleSystem")}
						<Switch checked={Boolean(system)} />
					</MDBox>
				)}

				<MDBox mt={1}>{i18n.t("SETTINGS.RULES.inputDictionary")}</MDBox>

				<MDBox mt={2} mb={1}>
					{!lod_.isEmpty(dictionariesList) ? (
						dictionariesList.map((item, index) => {
							return (
								<Chip
									key={index}
									style={{
										textTransform: "initial",
										cursor: "pointer",
										marginRight: "1%",
										marginBottom: "1%"
									}}
									color={selectedDictionnary.includes(item.codeDictionary) ? "info" : "default"}
									label={item.label?.fr}
									onClick={() => selectDictionary(item.codeDictionary)}
									icon={selectedDictionnary.includes(item.codeDictionary) ? <Delete /> : <Add />}
								/>
							);
						})
					) : (
						<div>{i18n.t("SETTINGS.RULES.emptyInput")}</div>
					)}
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default FormRule;
