import MDBox from "components/Basics/MDBox";
import MuiPhoneNumber from "mui-phone-number";

const InputPhone = ({ label, value, onChange, disabled, error, InputProps = {}, ...rest }) => {
	return (
		<MDBox mt={1} {...rest}>
			<MuiPhoneNumber
				defaultCountry="fr"
				onChange={e => onChange(e)}
				type="tel"
				className="dialogInput"
				label={label}
				value={value}
				variant="outlined"
				disabled={disabled}
				InputProps={InputProps}
				style={{
					height: "100%"
				}}
			/>
		</MDBox>
	);
};
export default InputPhone;
