import { Icon, IconButton } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import { useState } from "react";

const TipBar = () => {
	/* Get tip value from local storage */
	const getTipLocalStorage = () => {
		let storage = localStorage.getItem("faibrik-tip-answer-type");
		if (storage === "true" || storage === true) {
			return true;
		}
		if (storage === "false" || storage === false) {
			return false;
		}
		return true;
	};

	const [displayTip, setDisplayTip] = useState(getTipLocalStorage());

	if (!displayTip) return null;

	return (
		<MDBox
			mb={2}
			display="flex"
			borderRadius="md"
			p={1}
			pl={2}
			shadow="md"
			className="customInputCron"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
		>
			<MDBox display="flex" flexDirection="row" alignItems="center">
				<Icon>info</Icon>&nbsp;
				<MDTypography fontSize="small" variant="caption" fontWeight="bold">
					{t("COMPONENT.TIPBAR.description")}
				</MDTypography>
			</MDBox>
			<IconButton
				size="small"
				onClick={() => {
					setDisplayTip(false);
					localStorage.setItem("faibrik-tip-answer-type", false);
				}}
			>
				<Icon>close</Icon>
			</IconButton>
		</MDBox>
	);
};

export default TipBar;
