/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const svgIcon = {
	defaultProps: {
		fontSize: "inherit"
	},

	styleOverrides: {
		fontSizeInherit: {
			fontSize: "inherit !important"
		},

		fontSizeSmall: {
			fontSize: `${pxToRem(20)} !important`
		},

		fontSizeLarge: {
			fontSize: `${pxToRem(36)} !important`
		}
	}
};

export default svgIcon;
