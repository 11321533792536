/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * #######################################################@
 *
 * Error page
 *
 * Mandatory page to display errors on screen
 *
 * #######################################################@
 */
import { useAuth0 } from "react-auth0-wrapper";
import logo from "assets/images/logo.png";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import { Divider } from "@mui/material";

const ForbiddenPage = ({ status, code, message, logout }) => {
	return (
		<MDBox bgColor="light" className="loadingBox" display="flex" flexDirection="column">
			<MDBox display="flex" justifyContent="center" alignItems="center">
				<img src={logo} alt="logo" width="15%" />
			</MDBox>
			<MDBox
				mt={10}
				display="flex"
				justifyContent="center"
				flexDirection="column"
				alignItems="center"
			>
				<MDTypography color="dark" variant="h4" fontWeight="bold">
					{`Vous n'avez pas accès à cette application`}
				</MDTypography>
				<MDTypography color="dark" variant="h6" fontWeight="bold">
					Merci de contacter votre administrateur
				</MDTypography>
			</MDBox>
			<MDBox mt={5} display="flex" justifyContent="space-evenly" alignItems="center">
				<MDBox ml={2}>
					<MDButton
						variant="contained"
						color="info"
						onClick={() => {
							logout({ returnTo: window.location.origin });
						}}
					>
						Réessayer
					</MDButton>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

const InternalErrorPage = ({ status, code, message, logout }) => {
	return (
		<MDBox bgColor="light" className="loadingBox" display="flex" flexDirection="column">
			<MDBox display="flex" justifyContent="center" alignItems="center">
				<img src={logo} alt="logo" width="15%" />
			</MDBox>
			<MDBox
				mt={10}
				display="flex"
				justifyContent="center"
				flexDirection="column"
				alignItems="center"
			>
				<MDTypography color="dark" variant="h4" fontWeight="bold">
					Une erreur est survenue
				</MDTypography>
				<Divider variant="middle" color="dark" />
				<MDBox>
					<MDTypography color="dark" variant="h6" fontWeight="bold">
						{`Erreur : ${code} `}
					</MDTypography>
					<MDTypography color="dark" variant="h6" fontWeight="bold">
						{`"${message}"`}
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDBox mt={5} display="flex" justifyContent="space-evenly" alignItems="center">
				<MDBox ml={2}>
					<MDButton
						variant="contained"
						color="info"
						onClick={() => {
							logout({ returnTo: window.location.origin });
						}}
					>
						Réessayer
					</MDButton>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

const ErrorPage = ({ status, code, message }) => {
	const { logout } = useAuth0();

	switch (status) {
		case 401:
		case 403:
			return <ForbiddenPage status={status} code={code} message={message} logout={logout} />;
		default:
			return <InternalErrorPage status={status} code={code} message={message} logout={logout} />;
	}
};

export default ErrorPage;
