/* eslint-disable object-shorthand */
import { ClickAwayListener, InputAdornment, ListItemButton, Popper } from "@mui/material";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import FormAction from "redux-react/actions/formAction";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

/**
 * Custom image popper
 * @returns
 */
const CustomPopper = ({ openPoper, closePoper, images, selectImage, inputRef }) => {
	return (
		<ClickAwayListener
			onClickAway={() => {
				closePoper();
			}}
		>
			<Popper
				open={openPoper}
				anchorEl={inputRef?.current}
				placement="bottom-start"
				modifiers={[
					{
						name: "preventOverflow",
						options: {
							altAxis: true,
							boundariesElement: "viewport"
						}
					}
				]}
				style={{
					width: inputRef?.current?.clientWidth,
					zIndex: 9999,
					maxHeight: "40vh"
				}}
			>
				<MDBox bgColor="white" shadow="lg" p={2} borderRadius="md">
					{images.map((image, index) => (
						<ListItemButton key={index} onClick={e => selectImage(image)}>
							<MDBox
								sx={{
									height: "100%",
									width: "100%"
								}}
								component="img"
								src={image.logoURL}
								style={{ height: "1.5rem", width: "2rem", objectFit: "contain" }}
							/>
							<MDBox ml={2}>
								<MDTypography fontSize="small">{image.code}</MDTypography>
							</MDBox>
						</ListItemButton>
					))}
				</MDBox>
			</Popper>
		</ClickAwayListener>
	);
};

const InputLabel = ({
	label,
	disabled = false,
	value,
	onChange,
	item = {},
	path = "",
	listOfApis = [],
	error,
	...rest
}) => {
	const dispatch = useDispatch();

	const inputRef = useRef(null);
	const [images, setImages] = useState([]);
	const [inputSearch, setInputSearch] = useState("");
	const [openPoper, setOpenPoper] = useState(false);

	const [selectedImage, setSelectedImage] = useState({});

	let whitelistDynamic = item.whitelistDynamic || {};

	async function loadSuggestions(searchText, openPoper = true) {
		let promise = new Promise((resolve, reject) => {
			if (lod_.isEmpty(whitelistDynamic) || lod_.isEmpty(listOfApis)) {
				return;
			}
			// Text will be searched in the fields of the whitelistDynamic
			let searchFields = whitelistDynamic?.params?.searchFields;
			// API name and action to be used
			let apiName = whitelistDynamic?.apiName;
			let action = whitelistDynamic?.action;

			// Create a query for each field ($or operator)
			let querryData = [];
			searchFields.forEach(field => {
				const query = {};
				query[field] = { $regex: searchText, $options: "i" };
				querryData.push(query);
			});

			// Add the queried attribute to the query (search inside)
			let attribute = whitelistDynamic?.params?.attribute;
			querryData.push({ [attribute]: { $regex: searchText, $options: "i" } });

			// Get API config & action from the list of APIs
			let APIFind;
			let actionFind;
			if (apiName && action) {
				APIFind = listOfApis.find(item => item.name === apiName) || {};
				actionFind = APIFind.config.action[action];
			} else {
				// Nothing found => set empty images
				setImages([]);
				if (openPoper) setOpenPoper(inputRef.current);
			}

			// Get collection from action's config
			let collection = whitelistDynamic?.params?.collection ?? actionFind?.collection;
			// Get catalog from action's config
			let catalog = actionFind?.catalog;

			// Cannot execute request without collection
			if (!collection) {
				return;
			}

			// Call to back
			dispatch(
				FormAction.getItemsFromCollection(
					collection,
					{
						query: {
							$or: querryData
						},
						catalog: catalog
					},
					res => {
						setImages(res.items);
						if (openPoper) setOpenPoper(inputRef.current);
						resolve(res.items);
					}
				)
			);
		});
		return promise;
	}

	const closePoper = () => {
		setOpenPoper(null);
	};

	const selectImage = image => {
		closePoper();
		setSelectedImage(image);
		onChange(image.code);
		setInputSearch("");
	};

	useEffect(() => {
		const init = async () => {
			let images = await loadSuggestions("", false);
			let image = images.find(item => item.code === value);
			setSelectedImage(image);
		};
		if (lod_.isEmpty(selectedImage) && value) {
			init();
		}
	}, [listOfApis]);

	return (
		<MDBox ref={inputRef}>
			<MDInput
				fullWidth
				label={label}
				value={inputSearch}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					if (!openPoper) {
						loadSuggestions("");
						setOpenPoper(e.currentTarget);
					}
				}}
				onChange={e => {
					setInputSearch(e.target.value);
					loadSuggestions(e.target.value);
				}}
				InputProps={{
					startAdornment: !lod_.isEmpty(selectedImage) ? (
						<InputAdornment
							position="start"
							style={{
								height: "auto",
								maxHeight: "100vh"
							}}
						>
							<MDBox
								component="img"
								p={2}
								src={selectedImage.logoURL}
								style={{ height: "3rem", width: "4rem", objectFit: "contain" }}
							/>
							<MDBox p={2}>{selectedImage.code}</MDBox>
						</InputAdornment>
					) : null
				}}
			/>
			<CustomPopper
				openPoper={openPoper}
				closePoper={closePoper}
				images={images}
				selectImage={selectImage}
				inputRef={inputRef}
			/>
		</MDBox>
	);
};

export default InputLabel;
