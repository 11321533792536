/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-extra-boolean-cast */
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import humanizeDuration from "humanize-duration";
import { useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";

const InputCron = ({ item, label }) => {
	const profile = useSelector(selectCurrentProfile);
	const user = useSelector(state => state.user);

	const readableDuration = duration => {
		return humanizeDuration(duration, {
			language: (profile.language ?? user.language).toLowerCase()
		});
	};

	return (
		<MDBox
			mb={1}
			mt={1}
			display="flex"
			borderRadius="md"
			p={1}
			pl={2}
			shadow="md"
			className="customInputCron"
			flexDirection="column"
		>
			<MDTypography fontSize="medium" variant="caption" fontWeight="bold" sx={{ mt: 1 }}>
				{label}
			</MDTypography>
			<MDTypography fontSize="small" variant="overline">
				{readableDuration(item.defaultValue)}
			</MDTypography>
		</MDBox>
	);
};
export default InputCron;
