/* eslint-disable import/order */
/* eslint-disable no-unused-expressions */

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import i18n from "i18n";
import LogicManagerLine from "./LogicManagerLine";
import C from "./constants";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import lod_ from "lodash";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { useState } from "react";

const LogicManager = ({
	lineArray,
	handleRemoveLine,
	onChangeValue,
	dictionary,
	handleAddFilter
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	// Get all possibles methods for a given type
	function getMethod(type, filter) {
		let methods = [];
		switch (type) {
			case "string":
				methods = C.COMP_STRING;
				break;
			case "number":
				methods = C.COMP_NUMBER;
				break;
			case "boolean":
				methods = C.COMP_BOOLEAN;
				break;
			case "code":
				methods = C.COMP_CODE;
				break;
			case "datetime":
			case "date":
			case "timestamp": {
				methods = C.COMP_DATE;
				filter.filter?.type === "timestamp"
					? (methods = methods.filter(array => array.type !== "date"))
					: (methods = methods.filter(array => array.type !== "timestamp"));
				break;
			}
			default:
				methods = C.COMP_STRING;
				break;
		}
		if (filter.filter?.isArray) {
			methods = methods.filter(array => ["array", "title", "divider"].includes(array.type));
			let index = lod_.findIndex(methods, { title: "Opérateurs spécifiques" });

			if (methods.length === index + 1) {
				methods.splice(index, 1);
				methods.splice(index - 1, 1);
			}
			return methods;
		} else {
			let index = lod_.findIndex(methods, { title: "Opérateurs spécifiques" });

			if (methods.length === index + 1) {
				methods.splice(index, 1);
				methods.splice(index - 1, 1);
			}
			return filter.filter?.type === "code"
				? methods.filter(array => !["array", "arrayList"].includes(array.type))
				: methods.filter(array => !["array", "fieldSpe"].includes(array.type));
		}
	}

	return (
		<MDBox bgColor="white" borderRadius="lg" p={2}>
			<Table>
				<TableHead
					style={{
						display: "contents"
					}}
				>
					<TableRow>
						<TableCell style={{ width: "33%" }}>
							{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
						</TableCell>
						<TableCell style={{ width: "33%" }}>
							{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.method")}
						</TableCell>
						<TableCell style={{ width: "33%" }}>
							{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
						</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{lineArray.length === 0 && (
						<TableRow>
							<TableCell colSpan={5} align="center">
								{i18n.t("SETTINGS.CHARTS.COMPUTE.noFilters")}
							</TableCell>
						</TableRow>
					)}
					{lineArray.map((filter, index) => {
						return (
							<LogicManagerLine
								logicCode={filter.logicCode}
								dictionary={dictionary}
								name={filter.name}
								value={filter.value}
								filter={filter.filter}
								methods={getMethod(filter.filter.type, filter)}
								key={index}
								handleRemoveFilter={handleRemoveLine}
								onChangeValue={onChangeValue}
							/>
						);
					})}
					<MDBox m={1} display="flex">
						<MDButton
							fontSize="medium"
							color="light"
							variant="gradient"
							onClick={e => setAnchorEl(e.target)}
						>
							{i18n.t("SETTINGS.add")}
						</MDButton>
					</MDBox>
					<DictionaryMenu
						placement="right"
						dictionary={dictionary}
						anchorEl={anchorEl}
						handleInsertText={e => {
							setAnchorEl(null);
							handleAddFilter(e);
						}}
						handleClose={() => setAnchorEl(null)}
					/>
				</TableBody>
			</Table>
		</MDBox>
	);
};

export default LogicManager;
