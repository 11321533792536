import { changeLanguage } from "redux-react/reducers/profileReducer";

/**
 * Websocket message handler
 * @param {*} store
 * @param {*} socket
 * @param {*} event
 * @param {*} data
 */
export default function receiveWebsocketMessage({ dispatch, getState }, socket, event, data) {
	switch (event) {
		case "changeLanguage": {
			dispatch(changeLanguage(data));
			break;
		}
		default:
			break;
	}
}
