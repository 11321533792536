import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const loadingAssistant = initialState;

const slice = createSlice({
	name: "loadingAssistant",
	initialState,
	reducers: {
		setLoadingAssistant: (state, action) => {
			return action.payload;
		},
		resetLoadingAssistant: () => {
			return initialState;
		}
	}
});

export const { setLoadingAssistant, resetLoadingAssistant } = slice.actions;

export default slice.reducer;
