import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { t } from "i18next";

/**
 * Right pannel to add / remove / edit codes from a code list
 * @param {*} param0
 * @returns
 */
const PannelCode = ({ listCodes, setListCodes }) => {
	const addCodes = () => {
		let cloneListCodes = lod_.cloneDeep(listCodes);
		cloneListCodes.push({ value: "", label: "", valid: false });
		setListCodes(cloneListCodes);
	};

	const onChangeCode = (value, index) => {
		let cloneListCodes = lod_.cloneDeep(listCodes);
		cloneListCodes[index].value = value;
		if (cloneListCodes[index].value.trim() && cloneListCodes[index].label.trim()) {
			cloneListCodes[index].valid = true;
		} else {
			cloneListCodes[index].valid = false;
		}
		setListCodes(cloneListCodes);
	};

	const onChangeLabel = (value, index) => {
		let cloneListCodes = lod_.cloneDeep(listCodes);
		cloneListCodes[index].label = value;
		if (cloneListCodes[index].value.trim() && cloneListCodes[index].label.trim()) {
			cloneListCodes[index].valid = true;
		} else {
			cloneListCodes[index].valid = false;
		}
		setListCodes(cloneListCodes);
	};

	const deleteCodes = index => {
		let cloneListCodes = lod_.cloneDeep(listCodes);
		cloneListCodes.splice(index, 1);
		setListCodes(cloneListCodes);
	};

	const getErrorCodeBoolean = (value, index) => {
		if (!value?.trim()) {
			return true;
		}

		let exist = listCodes.find((objet, oIndex) => objet.value === value && oIndex !== index);

		if (exist) {
			return true;
		}
		return false;
	};

	const getSuccessCodeBoolean = (value, index) => {
		if (
			value?.trim() &&
			!listCodes.find((objet, oIndex) => objet.value === value && oIndex !== index)
		) {
			return true;
		}
		return false;
	};

	return (
		<MDBox display="flex" flex="1">
			<div style={{ borderLeft: "1px solid #ccc", height: "100%", margin: "0 10px" }} />
			<MDBox display="flex" flexDirection="column" width="100%" pt={1}>
				<MDBox
					sx={{ mb: 1 }}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					pt={1}
				>
					<MDTypography variant="h6">Liste des codes</MDTypography>

					<MDButton size="small" variant="contained" color="info" onClick={() => addCodes()}>
						Ajouter un code
					</MDButton>
				</MDBox>
				{lod_.isEmpty(listCodes) && (
					<MDBox
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						pt={2}
					>
						<MDTypography variant="h6" style={{ opacity: 0.7 }}>
							{t("FORMS.emptyCodes")}
						</MDTypography>
					</MDBox>
				)}
				{listCodes.map((code, index) => (
					<MDBox display="flex" width="100%" alignItems="center" pt={1} key={index}>
						<MDInput
							error={getErrorCodeBoolean(code.value, index)}
							success={getSuccessCodeBoolean(code.value, index)}
							flex="1"
							fullWidth
							value={code.value}
							onChange={e => onChangeCode(e.target.value, index)}
							sx={{ mr: 1 }}
							label="Code"
						/>
						<MDInput
							error={!code?.label?.trim()}
							success={code?.label?.trim()}
							flex="1"
							fullWidth
							value={code.label}
							onChange={e => onChangeLabel(e.target.value, index)}
							label="Label"
						/>
						<MDBox pl={1}>
							<IconButton size="small" onClick={() => deleteCodes(index)}>
								<Delete />
							</IconButton>
						</MDBox>
					</MDBox>
				))}
			</MDBox>
		</MDBox>
	);
};

export default PannelCode;
