import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const profile = initialState;

const slice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		selectProfile: (state, action) => {
			let { assistants, profile } = action.payload;

			/**
			 * Save selected assistant in profile
			 */
			profile.selectedAssistant = assistants[profile.assistantID];

			return profile;
		},
		updateMenu: (state, action) => {
			const { menu } = action.payload;
			state.selectedAssistant.menu = menu;
		},
		changeLanguage: (state, action) => {
			return { ...state, language: action.payload.language };
		}
	}
});
const selectMenu = state => state.profile.selectedAssistant.menu;

export const selectCurrentProfile = state => state.profile;

export const selectNotificationsList = createSelector(
	[selectCurrentProfile, state => state.notifications],
	(profile, notifications) => {
		return notifications[profile.assistantID]?.list || [];
	}
);

export const selectFilteredSortedMenu = createSelector([selectMenu], menu => {
	return menu.filter(item => item.type === "route" && !item.system).sort((a, b) => a.rank - b.rank);
});

export const selectAllSortedMenu = createSelector([selectMenu], menu => {
	return menu.filter(item => item.type === "route").sort((a, b) => a.rank - b.rank);
});

export const { selectProfile, updateMenu, changeLanguage } = slice.actions;

export default slice.reducer;
