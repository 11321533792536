/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/**
 * Main application business page
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import { Card, Icon, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import { display } from "redux-react/reducers/snackBarReducer";
import SkillActions from "redux-react/actions/skillActions";
/**
 * Default component to display charts, works with dynamic datas
 */
export default function SkillDetails({ route }) {
	const dispatch = useDispatch();
	const { profile, user, filters } = useSelector(state => state);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	const rightPannelContainer = useRef(null);
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Page Charts
	const [charts, setCharts] = useState([]);
	// Filters configuration for the page
	const [pageFilters, setPageFilters] = useState([]);

	const [openPannel, setOpenPannel] = useState(false);
	const [selectedSkill, setSelectedSkill] = useState({});

	const [reloadTable, setReloadTable] = useState(false);

	const [anchorElMenu, setAnchorElMenu] = useState(null);

	/*
	 * Get assistantID from the profile in redux
	 */
	const assistantID = useSelector(state => state.profile?.assistantID);

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData(typeList) {
		return charts.filter(chart => typeList.includes(chart.type));
	}
	/**
	 * Get charts with filters from the back
	 */
	async function getCharts(requestFilters, pageFilters) {
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);

		return new Promise((resolve, reject) => {
			dispatch(
				ChartsActions.getPageCharts(
					profile.assistantID,
					route.route,
					requestFilters,
					mandatoryFilters,
					false,
					route.system ?? false,
					res => resolve(res.charts)
				)
			);
		});
	}
	/**
	 * Get filters from back
	 */
	async function getPageFilters() {
		if (route.filter) {
			// If route has filter, get it
			return new Promise((resolve, reject) => {
				dispatch(
					ChartsActions.getPageFilters(profile.assistantID, route.filter, res => {
						resolve(res.filters);
					})
				);
			});
		} else {
			// return empty array
			return [];
		}
	}
	/**
	 * Load charts from back
	 */
	async function loadCharts() {
		// Get all filters from back
		let pageFilters = await getPageFilters();
		setPageFilters(pageFilters);
		// Get filters from local storage
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);
		// Build charts with filters
		let chartsFromDatabase = await getCharts(actualFilters, pageFilters);
		setCharts(chartsFromDatabase);
		setChartsLoading(false);
	}
	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		setChartsLoading(true);
		loadCharts();
	}, [profile.selectedAssistant.assistantID, route]);

	const closePannel = () => {
		setOpenPannel(false);
		setSelectedSkill({});
	};

	const askAccountForProfiles = skillName => {
		let skillCode = assistantID + "_" + skillName.trim().replace(/ /g, "_").toUpperCase();
		dispatch(
			display({
				message: `Récupération des profils en cours`,
				type: "info"
			})
		);
		dispatch(
			SkillActions.getProfilsBySkill(skillCode, assistantID, res => {
				// setSelectedTicket(res.conv || {});
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `Les profils ont été récupérés avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	/* Charts loader */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
	/* Main component */
		return (
			<DashboardLayout>
				<MDBox py={3}>
					{pageFilters && (
						<DashboardNavbar
							filters={[
								<MDBox
									display="flex"
									justifyContent="space-between"
									style={{
										width: "100%"
									}}
								>
									{/* First container */}
									<MDBox display="flex">
										{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
									</MDBox>
									{/* Second container */}
									<MDButton
										variant="contained"
										color="dark"
										onClick={e => {
											setAnchorElMenu(e.currentTarget);
										}}
									>
										<Icon>list_alt</Icon>&nbsp;Actions tickets
									</MDButton>
									{/* Menu */}
									<Menu
										anchorEl={anchorElMenu}
										open={Boolean(anchorElMenu)}
										onClose={() => setAnchorElMenu(null)}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center"
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "center"
										}}
									>
										{/* Actions */}
										<MenuItem
											onClick={() => {
												setAnchorElMenu(null);
											}}
										>
											<Icon>PlaceholderIcon</Icon>&nbsp;Placeholder
										</MenuItem>
									</Menu>
								</MDBox>
							]}
						/>
					)}
				</MDBox>

				{/* Pagined table */}
				{getChartsData(["paginedList"]).map((chart, index) => {
					return (
						<MDBox
							className="pageContentContainer"
							style={{
								display: openPannel ? "flex" : "block"
							}}
						>
							<MDBox
								flex="1"
								style={{
									width: openPannel ? "70%" : "auto"
								}}
							>
								<DefaultDataTable
									optionsAction="left"
									reloadTable={reloadTable}
									id={chart.code}
									list={chart}
									pagination={chart.pagination}
									canSearch
									key={index}
									table={chart.data}
									display={chart.request.attributesDisplay}
									filters={getLocalStorageBackValues(profile.assistantID, route.route, filters)}
									actions={[
										<IconButton
											handleclick={({ values }) => {
												askAccountForProfiles(values.name);
											}}
										>
											<Tooltip placement="top" title="Afficher les profils ayant le skill">
												<Icon>person-search</Icon>
											</Tooltip>
										</IconButton>,
										<IconButton
											customdisabled={({ values }) => {
												return values.analyze === undefined;
											}}
											handleclick={({ values }) => {
												setOpenPannel(true);
												setSelectedSkill(values);
												// if scroll in page is above rightPannelContainer ref, scroll to it
												setTimeout(() => {
													let scrollPosition = document.documentElement.scrollTop;
													let pannelTopPosiiton = rightPannelContainer.current.offsetTop;

													if (scrollPosition < pannelTopPosiiton) {
														rightPannelContainer.current.scrollIntoView({
															behavior: "smooth",
															block: "start"
														});
													}
												}, 200);
											}}
										>
											<Tooltip placement="top" title="Voir l'analyse">
												<Icon>chat</Icon>
											</Tooltip>
										</IconButton>
									]}
								/>
							</MDBox>

							{openPannel && (
								<MDBox className="rightPannelContainer" ref={rightPannelContainer}>
									<MDBox className="rightPannel" sx={{ ml: 2 }}>
										<Card sx={{ p: 2, height: "100%" }}>
											<MDBox display="flex" alignItems="center" justifyContent="space-between">
												<MDBox>Profils ayant le skill</MDBox>
												<MDBox>
													<Tooltip placement="top" title="Fermer">
														<IconButton onClick={() => closePannel()}>
															<Icon>close</Icon>
														</IconButton>
													</Tooltip>
												</MDBox>
											</MDBox>
											{selectedSkill.analyze ? (
												<MDBox>
													{/* Topics */}
													<MDBox>
														<MDTypography variant="h6" mt={2}>
															Topics
														</MDTypography>
														<MDBox p={1}>
															<MDTypography variant="body2" fontSize="small">
																{selectedSkill.analyze.topics.join(", ")}
															</MDTypography>
														</MDBox>
													</MDBox>
													{/* Sentiments */}
													<MDBox>
														<MDTypography variant="h6" mt={2}>
															Sentiments
														</MDTypography>
														<MDBox pl={1} pt={1}>
															<MDTypography variant="body2" fontSize="small">
																Sentiment client : {selectedSkill.analyze.sentimentClient}
															</MDTypography>
														</MDBox>
														<MDBox pl={1}>
															<MDTypography variant="body2" fontSize="small">
																Sentiment marque : {selectedSkill.analyze.sentimentMarque}
															</MDTypography>
														</MDBox>
														<MDBox pl={1}>
															<MDTypography variant="body2" fontSize="small">
																Sentiment produits : {selectedSkill.analyze.sentimentProduits}
															</MDTypography>
														</MDBox>
													</MDBox>
												</MDBox>
											) : (
												<MDBox
													style={{
														height: "100%"
													}}
													display="flex"
													justifyContent="center"
													alignItems="center"
												>
													<MDTypography variant="h6">
														Analyse non effectuée pour cette conversation
													</MDTypography>
												</MDBox>
											)}
										</Card>
									</MDBox>
								</MDBox>
							)}
						</MDBox>
					);
				})}
			</DashboardLayout>
		);
}
