/* eslint-disable no-restricted-syntax */
import { Icon, List, ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import lod_ from "lodash";
import { t } from "i18next";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
import SignatureComponent from "./components/Signature";
import GeneratereplyComponent from "./components/GenerateReply";

/**
 * Step 5: Advanced config / more options
 */
const Step5AdvancedSettings = ({
	validStep,
	channel,
	inputs,
	common,
	onChangeCommon,
	onChangeInputs
}) => {
	const [selectedMenu, setSelectedMenu] = useState(null);

	// Advanced settings menu
	const [menu, setMenu] = useState([
		{
			icon: "brush",
			label: t("CHANNEL.STEPS.ADVANCED.signature"),
			code: "signature",
			active: false
		},
		{
			icon: "emoji_objects",
			label: t("CHANNEL.STEPS.ADVANCED.generateReply"),
			code: "generateReply",
			active: false
		}
	]);

	/* Get content */
	const getContent = () => {
		switch (selectedMenu) {
			case "signature":
				return (
					<MDBox m={2}>
						<SignatureComponent channel={channel} common={common} onChange={onChangeCommon} />
					</MDBox>
				);
			case "generateReply":
				return (
					<MDBox m={2}>
						<GeneratereplyComponent onChange={onChangeInputs} channel={channel} inputs={inputs} />
					</MDBox>
				);
			default:
				return (
					<MDBox
						sx={{
							height: "100%",
							width: "100%"
						}}
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="center"
					>
						<MDTypography variant="body1" sx={{ opacity: 0.7 }}>
							{t("CHANNEL.STEPS.ADVANCED.selectSetting")}
						</MDTypography>
					</MDBox>
				);
		}
	};

	useEffect(() => {
		// Set the step as valid
		validStep(true);

		// Set the active menu
		let menuCopy = lod_.cloneDeep(menu);
		let channelAdvancedSettings = channel.advancedSettings ?? {};

		let activeSettings = Object.keys(channelAdvancedSettings).filter(
			key => channelAdvancedSettings[key] === true
		);

		for (let setting of activeSettings) {
			if (menuCopy.find(item => item.code === setting)) {
				menuCopy.find(item => item.code === setting).active = true;
			}
		}

		// Set the updated menu
		setMenu(menuCopy);

		// Select the first element active
		let firstActive = menuCopy.find(item => item.active);
		if (firstActive) {
			setSelectedMenu(firstActive.code);
		}
	}, []);

	return (
		<MDBox
			sx={{ height: "100%", width: "100%" }}
			display="flex"
			flexDirection="row"
			alignItems="stretch"
			style={{
				position: "relative"
			}}
		>
			<MDBox flex="1" bgColor="light" style={{ position: "sticky", top: "0px" }}>
				<List>
					<ListItem sx={{ mt: 2, mb: 2 }}>
						<MDTypography variant="body1" fontWeight="bold" sx={{ textTransform: "capitalize" }}>
							{t("CHANNEL.STEPS.ADVANCED.advancedSettings")}
						</MDTypography>
					</ListItem>
					{menu
						.filter(item => item.active)
						.map((item, index) => {
							return (
								<ListItemButton
									key={index}
									selected={selectedMenu === item.code}
									onClick={() => setSelectedMenu(item.code)}
									sx={{
										"&.Mui-selected": {
											backgroundColor: "info.main",
											color: "white.main",
											"&:hover": {
												backgroundColor: "info.main"
											}
										},
										"&.Mui-selected > *": {
											color: "white.main"
										},
										"&:hover": {
											backgroundColor: "info.light"
										},
										"&:hover > *": {
											color: "white.main"
										}
									}}
								>
									<ListItemIcon>
										<Icon color="dark">{item.icon}</Icon>
									</ListItemIcon>
									<MDTypography
										variant="body2"
										fontWeight="bold"
										sx={{ textTransform: "capitalize" }}
									>
										{item.label}
									</MDTypography>
								</ListItemButton>
							);
						})}
				</List>
			</MDBox>
			<MDBox
				flex="6"
				style={{
					overflowY: "auto"
				}}
			>
				{getContent()}
			</MDBox>
		</MDBox>
	);
};

export default Step5AdvancedSettings;
