/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/alt-text */
import { ClickAwayListener, Grid, InputAdornment, ListItemButton, Popper } from "@mui/material";
import axios from "axios";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { useEffect, useRef, useState } from "react";
import FormAction from "redux-react/actions/formAction";
import { useDispatch } from "react-redux";

/**
 * Custom image popper
 * @returns
 */
const CustomPopper = ({ openPoper, closePoper, images, selectImage, inputRef, size = "small" }) => {
	const [gridSize, setGridSize] = useState(1);
	const [imageSize, setImageSize] = useState("3rem");

	useEffect(() => {
		switch (size) {
			case "small":
				setGridSize(1);
				setImageSize("3rem");
				break;
			case "medium":
				setGridSize(2);
				setImageSize("6rem");
				break;
			case "large":
				setGridSize(3);
				setImageSize("9rem");
				break;
			default:
				break;
		}
	}, []);

	return (
		<ClickAwayListener
			onClickAway={() => {
				closePoper();
			}}
		>
			<Popper
				open={openPoper}
				anchorEl={inputRef?.current}
				placement="bottom-start"
				modifiers={[
					{
						name: "preventOverflow",
						options: {
							altAxis: true,
							boundariesElement: "viewport"
						}
					}
				]}
				style={{
					width: inputRef?.current?.clientWidth,
					zIndex: 9999,
					overflow: "auto",
					maxHeight: "40vh",
					borderRadius: "0.5rem",
					boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)"
				}}
			>
				<MDBox bgColor="white" p={2} borderRadius="md">
					<Grid container spacing={2}>
						{images.map((image, index) => (
							<Grid item xs={gridSize} key={index}>
								<ListItemButton onClick={e => selectImage(image)}>
									<MDBox
										sx={{
											height: "100%",
											width: "100%"
										}}
										component="img"
										src={image.url}
										style={{ height: imageSize, width: "100%", objectFit: "contain" }}
									/>
								</ListItemButton>
							</Grid>
						))}
					</Grid>
				</MDBox>
			</Popper>
		</ClickAwayListener>
	);
};

/**
 * Display a list of images in a selection menu
 * @returns
 */
const InputImageList = ({
	label,
	disabled = false,
	value,
	onChange,
	item = {},
	path = "",
	listOfApis = [],
	error,
	...rest
}) => {
	const dispatch = useDispatch();

	const inputRef = useRef(null);
	const [images, setImages] = useState([]);
	const [inputSearch, setInputSearch] = useState("");
	const [openPoper, setOpenPoper] = useState(false);

	let whitelistDynamic = item.whitelistDynamic || {};

	async function loadSuggestions(searchText) {
		// Text will be searched in the fields of the whitelistDynamic
		let searchFields = whitelistDynamic?.params?.searchFields;
		// API name and action to be used
		let apiName = whitelistDynamic?.apiName;
		let action = whitelistDynamic?.action;

		// Create a query for each field ($or operator)
		let querryData = [];
		searchFields.forEach(field => {
			const query = {};
			query[field] = { $regex: searchText };
			querryData.push(query);
		});

		// Add the queried attribute to the query (search inside)
		let attribute = whitelistDynamic?.params?.attribute;
		querryData.push({ [attribute]: { $regex: searchText } });

		// Get API config & action from the list of APIs
		let APIFind;
		let actionFind;
		if (apiName && action) {
			APIFind = listOfApis.find(item => item.name === apiName) || {};
			actionFind = APIFind.config.action[action];
		} else {
			// Nothing found => set empty images
			setImages([]);
			setOpenPoper(inputRef.current);
		}

		// Get collection from action's config
		let collection = whitelistDynamic?.params?.collection ?? actionFind?.collection;
		// Get catalog from action's config
		let catalog = actionFind?.catalog;

		// Cannot execute request without collection
		if (!collection) {
			return;
		}

		// Call to back
		dispatch(
			FormAction.getItemsFromCollection(
				collection,
				{
					query: {
						$or: querryData,
						assistantID: null
					},
					catalog: catalog
				},
				res => {
					setImages(res.items);
					setOpenPoper(inputRef.current);
				}
			)
		);
	}

	const closePoper = () => {
		setOpenPoper(null);
	};

	const selectImage = image => {
		closePoper();
		onChange(image.url);
		setInputSearch("");
	};

	return (
		<MDBox ref={inputRef}>
			<MDInput
				fullWidth
				label={label}
				value={inputSearch}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					if (!openPoper) {
						loadSuggestions("");
						setOpenPoper(e.currentTarget);
					}
				}}
				onChange={e => {
					setInputSearch(e.target.value);
					loadSuggestions(e.target.value);
				}}
				InputProps={{
					startAdornment: value ? (
						<InputAdornment
							position="start"
							style={{
								height: "auto",
								maxHeight: "100vh"
							}}
						>
							<MDBox
								component="img"
								p={2}
								src={value}
								style={{ height: "6rem", width: "6rem", objectFit: "contain" }}
							/>
						</InputAdornment>
					) : null
				}}
			/>
			<CustomPopper
				openPoper={openPoper}
				closePoper={closePoper}
				images={images}
				selectImage={selectImage}
				inputRef={inputRef}
			/>
		</MDBox>
	);
};

export default InputImageList;
