import AdvancedInput from "components/Custom/AdvancedInput";
import { t } from "i18next";
import { useState } from "react";

/**
 * Review type component
 * @param {*} param0
 * @returns
 */
const ReviewType = ({
	alternativeIndex,
	value,
	dictionary,
	setRightMenuPannel,
	language,
	languages,
	onChangePath
}) => {
	const [lastUpdate, setLastUpdate] = useState("");

	return (
		<AdvancedInput
			rounded
			trigger={alternativeIndex}
			valueCallback={(val, setValue, decode) => {
				if (val !== lastUpdate) {
					setValue(decode(val));
				}
			}}
			value={value}
			dictionary={dictionary}
			label={t("COMPONENT.ANSWERTYPE.typeAnswer")}
			onChange={e => {
				setLastUpdate(e);
				onChangePath("text", e);
			}}
			style={{
				borderRadius: "0.375rem 0 0 0.375rem"
			}}
			onFocus={() => {
				setRightMenuPannel({
					open: true,
					alternative: alternativeIndex,
					language: language.language,
					languages
				});
			}}
		/>
	);
};

export default ReviewType;
