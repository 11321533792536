/* eslint-disable no-unreachable-loop */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unreachable */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from "react";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { Divider, Icon, IconButton, Menu, MenuItem, Switch } from "@mui/material";
import MDTypography from "components/Basics/MDTypography";
import LogicManager from "components/Custom/LogicManager";
import { LittleForm } from "components/Custom/LittleForm";
import MDBadge from "components/Basics/MDBadge";
import i18n from "i18n";
import { createDictionarySkeleton } from "helpers/form";
import FormActions from "redux-react/actions/formAction";
import { useDispatch } from "react-redux";
import { queryDictionaries } from "helpers/dictionary";

/**
 * Condition box element, display logic / name / output from a rule config
 */
export default function ConditionBox({
	cond,
	rule,
	dictionary: mainDictionary,
	updateLogic,
	updateName,
	updateActive,
	outputDictionary,
	handleSaveOutput,
	removeCondition,
	listAPIs,
	setDisableDrag
}) {
	const [dictionary, setDictionary] = useState(mainDictionary);

	const dispatch = useDispatch();
	const [expended, setExpended] = useState(true);
	const [anchorElMenu, setAnchorElMenu] = useState(null);
	// Line array is used to display the rule's logic, 1 line = 1 condition
	const [lineArray, setLineArray] = useState([]);
	// Edit name bool
	const [editNameBool, setEditNameBool] = useState(false);
	// Temporary name
	const [temporaryName, setTemporaryName] = useState(cond.name);
	// Output skeleton used to edit the output
	const [outputSkeleton, setOutputSkeleton] = useState({});

	/**
	 * Add new "condition" (row) to the logic
	 * @param {*} filter
	 */
	const handleAddLine = filter => {
		let realPath = filter.replaceAll(".", ".items.");
		let dicObject = lod_.get(dictionary, realPath);
		let name = filter.split(".")[filter.split(".").length - 1];
		// user can add only one filter by attribute
		let existInArray = lineArray.find(f => f.name === name);

		let fullPath = realPath.replaceAll(".items.", ".");

		if (dicObject && !existInArray) {
			let copy = [
				...lineArray,
				{
					filter: {
						...dicObject,
						fullPath: fullPath
					},
					fullPath: fullPath,
					name
				}
			];

			setLineArray(prev => {
				return copy;
			});
		}
	};

	/**
	 * Save logic, convert JS object to JSON logic (json-logic-js)
	 * @param {*} list
	 */
	const saveLogic = list => {
		let partialParts = [];
		// Get only valid lines of the array (which have method, value and path)
		let validList = list.filter(f => f.method && f.path && f.logicCode);

		let logicCodes = [];
		// Create partial condition logic for each line
		for (let line of validList) {
			let partialCondition;
			switch (line.logicCode) {
				// For string_contain logic code, value must be the first item to check in string in 2nd pos
				case "string_contain": {
					let valueCheckContain = `"${line.value}"`;
					if (typeof line.value === "number") {
						valueCheckContain = `${line.value}`;
					}
					if (typeof line.value === "string") {
						let checkString = line.value.replace(/"/g, "");
						valueCheckContain = `"${checkString}"`;
					}
					partialCondition = `{ "${line.method}": [  ${valueCheckContain} , {{{ json ${line.path} }}} ] }`;
					break;
				}
				case "array_notequal_element": {
					partialCondition = `{ "${line.method}": {"in":[{{{ json ${line.path} }}}, ${JSON.stringify(
						line.value
					)}]}}`;
					break;
				}
				case "array_not_equals": {
					let valueIn = "";
					if (lod_.isEmpty(line.value)) {
						partialCondition = `{ "!": {"or":[{}]} }`;
						break;
					}
					for (let valueInLine of line.value) {
						let valueCheckContain = `"${valueInLine}"`;
						if (typeof valueInLine === "number") {
							valueCheckContain = `${valueInLine}`;
						}

						valueIn += `{ "in": [${valueCheckContain}, {{{ json ${line.path} }}}]},`;
					}
					valueIn = valueIn.slice(0, -1);
					partialCondition = `{ "!": {"or":[${valueIn}]} }`;
					break;
				}
				case "element_exist": {
					// exist => and
					// { "and": [{"!=": ["val",""]},{"!=": ["val",null]}] }
					partialCondition = `{ "and": [{"${line.method}": [{{{ json ${line.path} }}},""]},{"${line.method}": [{{{ json ${line.path} }}},null]}] }`;
					break;
				}
				case "element_not_exist": {
					// exist => or
					// { "or": [{"==": ["val",""]},{"==": ["val",null]}] }
					partialCondition = `{ "or": [{"${line.method}": [{{{ json ${line.path} }}},""]},{"${line.method}": [{{{ json ${line.path} }}},null]}] }`;
					break;
				}
				case "element_is_contains_in_array":
				case "element_is_in_array": {
					let valueIn = "";
					if (lod_.isEmpty(line.value)) {
						partialCondition = `{"or":[{}]}`;
						break;
					}
					for (let valueInLine of line.value) {
						let valueCheck = `"${valueInLine}"`;
						if (typeof valueInLine === "number") {
							valueCheck = `${valueInLine}`;
						}
						if (typeof valueInLine === "string") {
							let checkString = valueInLine.replace(/"/g, "");
							valueCheck = `"${checkString}"`;
						}
						valueIn += `{ "in": [${valueCheck}, {{{ json ${line.path} }}}]},`;
					}
					valueIn = valueIn.slice(0, -1);
					partialCondition = `{"or":[${valueIn}]}`;
					break;
				}
				default:
					partialCondition = `{"${line.method}":[{{{ json ${line.path} }}}, ${JSON.stringify(
						line.value
					)}]}`;
					break;
			}
			logicCodes.push(line.logicCode);
			partialParts.push(partialCondition);
		}
		// Build the json logic
		let buildedJsonLogic = `{ "and": [ ${partialParts.join(",")} ] }`;
		updateLogic({ logic: buildedJsonLogic, logicCodes: logicCodes });
	};

	/**
	 * Get query informations from a dictionary item.
	 * If the dictionary item is dynamic, it will return the collection and the attribute
	 * @param {*} path
	 * @returns
	 */
	const getQueryInformationsFromDictionaryItem = path => {
		let dictionaryItem = lod_.get(dictionary, path.trim().replaceAll(".", ".items."));
		if (dictionaryItem && dictionaryItem.isDynamic && dictionaryItem.actions?.ruleDynamicSearch) {
			const whitelistDynamic = dictionaryItem.whitelistDynamic;

			if (lod_.isNil(whitelistDynamic)) {
				return null;
			}

			const apiName = whitelistDynamic.apiName;
			const apiAction = whitelistDynamic.action;

			const api = listAPIs.find(f => f.name === apiName);
			if (!api) {
				return null;
			}

			const action = (api.config.action ?? {})[apiAction];

			if (lod_.isNil(action)) {
				return null;
			}

			const collection = action.collection;
			const attribute = whitelistDynamic.params.attribute;

			return {
				collection,
				attribute
			};
		}

		return null;
	};

	/**
	 * Remove a "condition" from the logic
	 * @param {*} name - Name of the condition to remove
	 */
	const handleRemoveLine = name => {
		setLineArray(prev => {
			let updatedArray = prev.filter(f => f.name !== name);
			saveLogic(updatedArray);
			return updatedArray;
		});
	};

	/**
	 * Delete all rows that not match with the current
	 * dictionary's keys
	 */
	function deleteUnusedRows(sourceDictionary = mainDictionary) {
		let dictionaryKeys = Object.keys(sourceDictionary);

		for (let row of lineArray) {
			let path = row.fullPath ?? row.path;
			let pathDictionary = path.split(".")[0];
			if (!dictionaryKeys.includes(pathDictionary)) {
				handleRemoveLine(row.name);
			}
		}
	}

	/**
	 * Delete a row controller
	 * Delete the row and handle special cases
	 * @param {*} name
	 * @returns
	 */
	const handleRemoveLineController = name => {
		// 1- Get item
		let item = lineArray.find(f => f.name === name);
		if (!item) {
			return;
		}

		// 2- Remove the line
		handleRemoveLine(name);

		// 3- Special cases
		let queryInformations = getQueryInformationsFromDictionaryItem(item.fullPath ?? item.path);
		if (queryInformations) {
			setDictionary(prev => {
				return mainDictionary;
			});
			deleteUnusedRows(mainDictionary);
		}
	};

	/**
	 * Query items in a collection from the API
	 * @param {*} value
	 * @returns
	 */
	async function queryCollection(collection, attribute, value) {
		return new Promise((resolve, reject) => {
			dispatch(
				FormActions.getItemsFromCollection(
					collection,
					{
						query: {
							[attribute]: { $in: value }
						}
					},
					res => {
						resolve(res.items);
					}
				)
			);
		});
	}

	/**
	 * Add a new collection field to the dictionary's list
	 * User can add multiple match based on the collection of whitelist item
	 * that he wants to use
	 * @param {[String?]} value - Values of the list
	 * @param {Object} updatedList - Updated list of the lineArray
	 * @returns
	 */
	async function addNewCollectionField(collection, attribute, value, updatedList) {
		// If the value is empty, set the main dictionary
		if (lod_.isEmpty(value)) {
			setDictionary(mainDictionary);
			deleteUnusedRows(mainDictionary);
			return;
		}

		// Get the collection's items
		const collectionItems = await queryCollection(collection, attribute, value);
		// Get all dictionaries
		let dictionariesCode = collectionItems.map(item => item.format.dictionary);

		// If the dictionaries are empty, set the main dictionary
		if (lod_.isEmpty(dictionariesCode)) {
			setDictionary(mainDictionary);
			deleteUnusedRows(mainDictionary);
			return;
		}

		const dictionaries = await queryDictionaries(dispatch, dictionariesCode);

		// Merge the dictionaries
		setDictionary({
			...mainDictionary,
			...dictionaries
		});

		// Update the line's array
		setLineArray(prev => updatedList);
		saveLogic(updatedList);
	}

	/* handle logic chanche */
	const handleOnChange = async obj => {
		// Update the line's array

		let updatedList = lineArray.map(f => {
			if (f.name === obj.name) {
				// Only method / value can change
				f.method = obj.method;
				f.value = obj.value;
				f.path = obj.path;
				f.logicCode = obj.logicCode;
			}
			return f;
		});

		/**
		 * Special case for dynamic values
		 */
		let queryInformations = getQueryInformationsFromDictionaryItem(obj.path);
		if (queryInformations) {
			await addNewCollectionField(
				queryInformations.collection,
				queryInformations.attribute,
				obj.value,
				updatedList
			);
		}
		setLineArray(prev => updatedList);
		saveLogic(updatedList);
	};
	/* Save condition name */
	const saveNewName = value => {
		if (!value.trim()) return;
		setTemporaryName(value);
	};
	/* Handle change output values */
	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(outputSkeleton);
		lod_.set(clone, path, value);
		handleSaveOutput(clone);
		setOutputSkeleton(clone);
	};

	/**
	 * Get extra dictionaries based on a collection
	 * @param {*} value
	 * @returns
	 */
	async function getExtraDictionaries(collection, attribute, value) {
		// Get the resource's items
		const resources = await queryCollection(collection, attribute, value);
		// Get all dictionaries
		let dictionariesCode = resources.map(item => item.format.dictionary);
		// If the dictionaries are empty return empty object
		if (lod_.isEmpty(dictionariesCode)) {
			return {};
		}
		const dictionaries = await queryDictionaries(dispatch, dictionariesCode);
		return dictionaries;
	}

	/**
	 * Initialize the logic on mount
	 */
	async function initLogic() {
		let sourceDictionary = lod_.cloneDeep(mainDictionary);

		let skeleton = createDictionarySkeleton(outputDictionary, cond.output);
		setOutputSkeleton(skeleton);

		setLineArray(prev => []);

		let newLineArray = [];

		// Parse logic to JSON
		let logic = cond.logic;
		logic = logic.replaceAll("{{{ json", '"');
		logic = logic.replaceAll("}}}", '"');
		let logicJSON = JSON.parse(logic);
		// Get only the 'and' part of the logic (actually must be the only one)
		logicJSON = logicJSON.and;
		// For each operator, get the filter, method and value
		for (let i = 0; i < logicJSON.length; i++) {
			let logicCode = cond.logicCodes[i];
			let singleOperator = logicJSON[i];
			let operator = Object.keys(singleOperator)[0];
			let item;
			let value;

			switch (logicCode) {
				// For string_contain logic code, value must be the first item to check in string in 2nd pos
				case "string_contain":
					item = singleOperator[operator][1];
					value = singleOperator[operator][0];
					break;
				case "array_notequal_element": {
					item = singleOperator[operator]?.in[0];
					value = singleOperator[operator]?.in[1];
					break;
				}
				case "array_not_equals": {
					if (lod_.isEmpty(singleOperator[operator].or[0])) {
						item = "";
					} else {
						item = singleOperator[operator].or[0].in[1].trim();
					}
					let valueInArray = [];

					for (let valueOperator of singleOperator[operator].or) {
						if (!lod_.isEmpty(valueOperator)) {
							valueInArray.push(valueOperator.in[0]);
						} else {
							valueInArray.push("");
						}
					}

					value = valueInArray;
					break;
				}
				case "element_is_contains_in_array":
				case "element_is_in_array": {
					if (lod_.isEmpty(singleOperator[operator][0])) {
						item = "";
					} else {
						item = singleOperator[operator][0].in[1].trim();
					}

					let valueInArray = [];

					for (let valueOperator of singleOperator[operator]) {
						if (!lod_.isEmpty(valueOperator)) {
							valueInArray.push(valueOperator.in[0]);
						} else {
							valueInArray.push("");
						}
					}
					value = valueInArray;

					break;
				}
				case "element_exist": {
					// exist => and
					// { "and": [{"!=": ["val",""]},{"!=": ["val",null]}] }
					if (operator === "in") {
						// old way, it will be removed with the time
						item = singleOperator[operator][0];
						value = singleOperator[operator][1];
					} else {
						// new way
						item = singleOperator[operator][0]["!="][0].trim();
						value = null;
					}
					break;
				}
				case "element_not_exist": {
					// exist => or
					// { "or": [{"==": ["val",""]},{"==": ["val",null]}] }
					item = singleOperator[operator][0]["=="][0].trim();
					value = null;
					break;
				}
				default:
					item = singleOperator[operator][0];
					value = singleOperator[operator][1];
					break;
			}

			/**
			 * Special case for hooks dynamic values
			 */
			let queryInformations = getQueryInformationsFromDictionaryItem(item?.trim());
			if (queryInformations) {
				let dictionaries = await getExtraDictionaries(
					queryInformations.collection,
					queryInformations.attribute,
					value
				);

				setDictionary(prev => {
					return {
						...mainDictionary,
						...dictionaries
					};
				});

				lod_.merge(sourceDictionary, dictionaries);
			}

			let dicPath = item.replaceAll(".", ".items.");

			let dictionaryItem = lod_.get(sourceDictionary, dicPath.trim());

			if (!dictionaryItem) {
				continue;
			}

			newLineArray.push({
				filter: {
					...dictionaryItem,
					fullPath: item.trim()
				},
				name: dictionaryItem?.attribute,
				path: item.trim(),
				value: value,
				logicCode: logicCode
			});
		}

		setLineArray(prevState => newLineArray);
	}

	/* Fill logic fields on component load */
	useEffect(() => {
		initLogic();
	}, []);

	const handleBlur = () => {
		setEditNameBool(false);
		updateName(temporaryName);
	};

	const handleDoubleClick = () => {
		setEditNameBool(true);
	};

	return (
		<MDBox bgColor="light" p={2} mb={1} borderRadius="lg">
			{/* Condition's name */}
			{editNameBool && (
				<MDBox mb={1} onDoubleClick={handleDoubleClick} display="flex" alignItems="center">
					<IconButton color="success" onClick={saveNewName}>
						<Icon>check</Icon>
					</IconButton>
					<MDInput
						size="large"
						type="text"
						fullWidth
						value={temporaryName}
						onChange={e => {
							saveNewName(e.target.value);
						}}
						inputProps={{
							style: {
								fontSize: "1.5rem",
								color: "#344767",
								fontWeight: "700",
								letterSpacing: "0.00735em",
								opacity: "1",
								textTransform: "none",
								verticalAlign: "unset"
							}
						}}
						onBlur={handleBlur}
					/>
				</MDBox>
			)}
			{!editNameBool && (
				<MDBox display="flex" alignItems="center" justifyContent="space-between">
					<MDBox display="flex" alignItems="center">
						<IconButton onClick={e => setEditNameBool(true)}>
							<Icon>edit</Icon>
						</IconButton>
						<MDTypography variant="h4" onDoubleClick={e => setEditNameBool(true)}>
							{cond.name}
						</MDTypography>
					</MDBox>
					<MDBox display="flex" alignItems="center">
						<MDBadge
							color={Boolean(cond.active) ? "success" : "error"}
							badgeContent={Boolean(cond.active) ? "Actif" : "Inactif"}
						/>
						<IconButton
							onClick={e => {
								setAnchorElMenu(e.currentTarget);
							}}
						>
							<Icon>more_vert</Icon>
						</IconButton>
						<IconButton
							onClick={() => {
								setExpended(!expended);
							}}
						>
							<Icon>{expended ? "expand_less" : "expand_more"}</Icon>
						</IconButton>
						{/* Menu */}
						<Menu
							anchorEl={anchorElMenu}
							open={Boolean(anchorElMenu)}
							onClose={() => setAnchorElMenu(null)}
						>
							{/* Actions */}
							<MenuItem disabled style={{ opacity: 1 }}>
								<MDTypography variant="caption">Actions</MDTypography>
							</MenuItem>
							<MenuItem onClick={() => removeCondition(cond.name)}>
								<Icon>delete</Icon>&nbsp;Supprimer
							</MenuItem>
							{/* Options */}
							<Divider />
							<MenuItem disabled style={{ opacity: 1 }}>
								<MDTypography variant="caption">Options</MDTypography>
							</MenuItem>
							<MenuItem
								style={{ justifyContent: "space-between" }}
								onClick={() => updateActive(!Boolean(cond.active))}
							>
								Actif
								<Switch checked={Boolean(cond.active)} />
							</MenuItem>
						</Menu>
					</MDBox>
				</MDBox>
			)}
			{expended && (
				<>
					<Divider />
					{/* Logic manager */}
					<MDBox mt={1} mb={1}>
						<LogicManager
							lineArray={lineArray}
							handleRemoveLine={handleRemoveLineController}
							onChangeValue={handleOnChange}
							dictionary={dictionary}
							handleAddFilter={handleAddLine}
						/>
					</MDBox>
					{/* Output */}
					<MDBox mt={2}>
						<MDTypography variant="h5">{i18n.t("SETTINGS.DICTIONARY.output")}</MDTypography>
					</MDBox>
					<MDBox
						bgColor="white"
						borderRadius="lg"
						p={2}
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
						}}
						// Disable drag when hovering the output
						onMouseEnter={() => {
							setDisableDrag(true);
						}}
						onMouseLeave={() => {
							setDisableDrag(false);
						}}
					>
						{!lod_.isEmpty(outputSkeleton) && (
							<LittleForm
								object={outputDictionary}
								metadatasSkeleton={outputSkeleton}
								handleChange={handleChange}
								listAPIs={listAPIs}
							/>
						)}
					</MDBox>
				</>
			)}
		</MDBox>
	);
}
