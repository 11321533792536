/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// Custom styles for MDButton
import MDButtonRoot from "components/Basics/MDButton/MDButtonRoot";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

const MDButton = forwardRef(
	(
		{
			color = "white",
			variant = "contained",
			size = "medium",
			circular = false,
			iconOnly = false,
			children = false,
			...rest
		},
		ref
	) => {
		const [controller] = useMaterialUIController();
		const { darkMode } = controller;

		return (
			<MDButtonRoot
				{...rest}
				ref={ref}
				color="primary"
				variant={variant === "gradient" ? "contained" : variant}
				size={size}
				ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
			>
				{children}
			</MDButtonRoot>
		);
	}
);

export default MDButton;
