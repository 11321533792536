/* eslint-disable prefer-destructuring */
/* eslint-disable no-unreachable */
/* eslint-disable import/no-extraneous-dependencies */
import "../style.css";
import { LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import ReactJson from "react-json-view";
import { t } from "i18next";

import MDBadge from "components/Basics/MDBadge";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { PG_PROCESS_STATES } from "../../constants";

const ProcessRow = ({ process, llmModel, state = {} }) => {
	let step = process.steps[0];
	let outputs = step.outputs;
	let model = llmModel.find(model => model.code === step.modelCode);

	const getContent = () => {
		switch (state?.state) {
			case PG_PROCESS_STATES.RUNNING:
				return (
					<MDBox m={1}>
						<MDTypography
							variant="body2"
							fontSize="small"
							style={{
								fontWeight: "bold"
							}}
						>
							{t("PROMPT.PLAYGROUND.RUN.inProgressRun")}
						</MDTypography>
						<MDBox mt={1}>
							<LinearProgress
								color="info"
								style={{
									overflow: "hidden"
								}}
							/>
						</MDBox>
					</MDBox>
				);
			case PG_PROCESS_STATES.DONE: {
				if (outputs.isJson) {
					return (
						<ReactJson
							src={state?.result}
							name={null}
							style={{
								fontSize: "medium"
							}}
							enableClipboard={false}
							displayDataTypes={false}
						/>
					);
				} else {
					let mappingKey = outputs.mappingKey;
					return (
						<MDTypography variant="body1" fontSize="small">
							{state.result[mappingKey]}
						</MDTypography>
					);
				}
			}
			case PG_PROCESS_STATES.ERROR:
				return (
					<MDTypography variant="body1" fontSize="small">
						{state.error}
					</MDTypography>
				);
			default:
				return <MDBox></MDBox>;
		}
	};

	const getBadgeProcess = code => {
		switch (state?.state) {
			case PG_PROCESS_STATES.WAITING:
				return {
					color: "info",
					content: t("PROMPT.PLAYGROUND.RUN.STATES.waiting")
				};
			case PG_PROCESS_STATES.RUNNING:
				return {
					color: "warning",
					content: t("PROMPT.PLAYGROUND.RUN.STATES.inProgress")
				};
			case PG_PROCESS_STATES.RESULT:
				return {
					color: "info",
					content: t("PROMPT.PLAYGROUND.RUN.STATES.waiting")
				};
			case PG_PROCESS_STATES.DONE:
				return {
					color: "success",
					content: t("PROMPT.PLAYGROUND.RUN.STATES.DONE")
				};
			default:
				return {
					color: "error",
					content: "error"
				};
		}
	};

	return (
		<MDBox component="tr">
			<TableCell>
				<MDTypography variant="body1" fontSize="small">
					{process.name}
				</MDTypography>
			</TableCell>
			<TableCell>
				<MDBadge
					color={getBadgeProcess(process.code).color}
					circular
					variant="contained"
					size="xs"
					badgeContent={getBadgeProcess(process.code).content}
					container
				></MDBadge>
			</TableCell>
			<TableCell>
				<MDTypography variant="body1" fontSize="small">
					{model.name}
				</MDTypography>
			</TableCell>
			<TableCell>
				<MDBox style={{ minWidth: "40vw" }}>{getContent()}</MDBox>
			</TableCell>
		</MDBox>
	);
};

const RunningPage = ({ process = [], llmModel, options, state }) => {
	return (
		<MDBox style={{ width: "100%", height: "100%", overflow: "auto" }}>
			<Table>
				<TableHead
					style={{
						display: "contents"
					}}
				>
					<TableRow>
						<TableCell>{t("PROMPT.PLAYGROUND.RUN.process")}</TableCell>
						<TableCell>{t("PROMPT.PLAYGROUND.RUN.state")}</TableCell>
						<TableCell>{t("PROMPT.PLAYGROUND.RUN.model")}</TableCell>
						<TableCell sx={{ minWidth: "40vw" }}>{t("PROMPT.PLAYGROUND.RUN.result")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{process.map((process, index) => {
						return (
							<ProcessRow
								key={index}
								process={process}
								llmModel={llmModel}
								state={state[process?.code]}
							/>
						);
					})}
				</TableBody>
			</Table>
		</MDBox>
	);
};

export default RunningPage;
