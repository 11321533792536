import "./style.css";
import { Icon, IconButton, Menu } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useState } from "react";

/**
 * Display answer item component, with a preview of the content
 * @param {Boolean} selected - If the item is selected
 * @param {String} content - The content of the answer (could be HTML)
 * @param {Object} item - The item object
 * @param {Function} items - The items to display in the menu. Works as : (give possibility to close the menu)
 * items={(closeMenu) => [<MenuItem onClick={closeMenu}>Item</MenuItem>]
 * @param {Function} onClick - The function to call when the card is clicked
 * @returns
 */
const AnswerItem = ({ selected = false, content, item, items = () => {}, onClick = () => {} }) => {
	const [anchorEl, setAnchorEl] = useState(false);

	/* Close the options menu */
	const closeMenu = e => {
		e?.stopPropagation();
		setAnchorEl(null);
	};

	/* Compute the actions menu */
	const menu = () => {
		let computedItems = items(closeMenu);

		return (
			<Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
				{computedItems}
			</Menu>
		);
	};

	/* Component */
	return (
		<MDBox
			className={`answer-item-card ${selected ? "answer-item-card-selected" : ""}`}
			borderRadius="lg"
			shadow="md"
			onClick={onClick}
		>
			{content && (
				<MDBox
					className="answer-item-card-preview"
					p={5}
					dangerouslySetInnerHTML={{ __html: content }}
				/>
			)}
			<MDBox className="answer-item-card-overlay">
				<MDBox className="answer-item-card-text">
					<MDTypography variant="h6">{item.name}</MDTypography>
					<MDTypography variant="body1">{item.description}</MDTypography>
				</MDBox>
			</MDBox>
			<MDBox className="answer-item-card-actions">
				<IconButton
					color="info"
					onClick={e => {
						e.stopPropagation();
						setAnchorEl(e.currentTarget);
					}}
				>
					<Icon>more_vert</Icon>
				</IconButton>
			</MDBox>
			{menu()}
		</MDBox>
	);
};

export default AnswerItem;
