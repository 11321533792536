/* eslint-disable no-extra-boolean-cast */

import {
	Card,
	CardActions,
	CardContent,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Slider,
	Switch,
	ThemeProvider,
	createTheme
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";

/**
 * Generate reply component
 */
const GeneratereplyComponent = ({ onChange, channel, inputs }) => {
	const TONALITY_LIST = [
		"cordial",
		"happily",
		"serious",
		"casual",
		"familiar",
		"formal",
		"polite",
		"professional"
	];

	const theme = createTheme({
		components: {
			MuiSlider: {
				styleOverrides: {
					rail: {
						color: "#ddd"
					},
					track: {
						color: "#007aff"
					},
					thumb: {
						color: "#007aff"
					}
				}
			}
		}
	});

	return (
		<MDBox sx={{ height: "100%", width: "100%" }} display="flex" justifyContent="center">
			<MDBox>
				<MDBox display="flex" alignItems="center" pb={2}>
					<MDBox
						sx={{
							height: "3rem",
							width: "3rem"
						}}
						mr={1}
					>
						<MDBox
							component="img"
							borderRadius="md"
							src={channel.logoURL}
							alt={channel.logoURL}
							sx={{
								width: "100%",
								height: "auto",
								objectFit: "contain"
							}}
						/>
					</MDBox>

					<MDTypography variant="h4">{`${i18n.t("CHANNEL.configureAnswer")} ${channel.name ? channel.name : ""}`}</MDTypography>
				</MDBox>
				{/* <MDBox display="flex" alignItems="stretch" mt={3}> */}
				<Grid container spacing={2}>
					{/** Emojis */}
					{/* <MDBox flex="1" mr={1}> */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox mt={2}>
									<MDTypography variant="h6">{i18n.t("CONFIG.emojis")}</MDTypography>
									<MDBox>
										<MDBox mt={1} display="flex" alignItems="start">
											<ThemeProvider theme={theme}>
												<Slider
													defaultValue={inputs?.emojisCount?.value}
													marks={[
														{
															value: 0,
															label: "0"
														},
														{
															value: 1,
															label: "1"
														},
														{
															value: 2,
															label: "2"
														},
														{
															value: 3,
															label: "3"
														},
														{
															value: 4,
															label: "4"
														},
														{
															value: 5,
															label: "5"
														}
													]}
													min={0}
													max={5}
													onChange={(e, value) => {
														onChange(`emojisCount.value`, value);
													}}
												/>
											</ThemeProvider>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
							<CardActions>
								<MDBox
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									onClick={e => {
										onChange(`emojisCount.active`, !Boolean(inputs.emojisCount?.active));
									}}
								>
									<span>{i18n.t("CONFIG.activeUser")}</span>
									<MDBox>
										<Switch
											checked={Boolean(inputs.emojisCount?.active)}
											onChange={(e, value) => {
												onChange(`emojisCount.active`, value);
											}}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>
					{/* </MDBox> */}

					{/** Tonalité */}
					{/* <MDBox flex="1" mr={1} ml={1}> */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox mt={2}>
									<MDTypography variant="h6">{i18n.t("CONFIG.answerTone")}</MDTypography>
									<MDBox>
										<MDBox mt={1} display="flex" alignItems="start">
											<FormControl fullWidth>
												<InputLabel id="select-label">{i18n.t("CONFIG.answerTone")}</InputLabel>
												<Select
													labelId="select-label"
													id="select"
													label={i18n.t("CONFIG.answerTone")}
													value={inputs.answerTone?.value}
													onChange={e => {
														onChange(`answerTone.value`, e.target.value);
													}}
												>
													{TONALITY_LIST.map((option, index) => (
														<MenuItem key={index} value={i18n.t(`CONFIG.tonalities.${option}`)}>
															{i18n.t(`CONFIG.tonalities.${option}`)}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
							<CardActions>
								<MDBox display="flex" justifyContent="space-between" alignItems="center">
									<span>{i18n.t("CONFIG.activeUser")}</span>
									<MDBox>
										<Switch
											onChange={(e, value) => {
												onChange(`answerTone.active`, value);
											}}
											checked={Boolean(inputs.answerTone?.active)}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>
					{/* </MDBox> */}

					{/** Forme */}
					{/* <MDBox flex="1" mr={1} ml={1}> */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox mt={2}>
									<MDTypography variant="h6">{i18n.t("CONFIG.youForm")}</MDTypography>
									<MDBox>
										<MDBox mt={1} flex="1" display="flex" alignItems="start">
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												defaultValue={inputs?.youForm?.value}
												name="radio-buttons-group"
												row
											>
												<FormControlLabel
													value="vouvoiement"
													control={
														<Radio
															onClick={() => {
																onChange(`youForm.value`, "vouvoiement");
															}}
														/>
													}
													label={i18n.t("CONFIG.vouvoiement")}
												/>
												<FormControlLabel
													value="tutoiement"
													control={
														<Radio
															onClick={() => {
																onChange(`youForm.value`, "tutoiement");
															}}
														/>
													}
													label={i18n.t("CONFIG.tutoiement")}
												/>
											</RadioGroup>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
							<CardActions>
								<MDBox display="flex" justifyContent="space-between" alignItems="center">
									<span>{i18n.t("CONFIG.activeUser")}</span>
									<MDBox>
										<Switch
											onChange={(e, value) => {
												onChange(`youForm.active`, value);
											}}
											checked={Boolean(inputs.youForm?.active)}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>
					{/* </MDBox> */}

					{/** Response lenght */}
					{/* <MDBox flex="1" mr={1} ml={1}> */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox mt={2}>
									<MDTypography variant="h6">{i18n.t("CONFIG.maximumWords")}</MDTypography>
									<MDBox>
										<MDBox mt={1}>
											<ThemeProvider theme={theme}>
												<Slider
													aria-label="Volume"
													defaultValue={inputs?.maximumWords?.value}
													step={10}
													min={20}
													max={100}
													onChange={(e, value) => {
														onChange(`maximumWords.value`, value);
													}}
												/>
											</ThemeProvider>
											<MDBox display="flex" justifyContent="space-between">
												<MDTypography variant="caption">{i18n.t("CONFIG.short")}</MDTypography>
												<MDTypography variant="caption">{i18n.t("CONFIG.long")}</MDTypography>
											</MDBox>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
							<CardActions>
								<MDBox display="flex" justifyContent="space-between" alignItems="center">
									<span>{i18n.t("CONFIG.activeUser")}</span>
									<MDBox>
										<Switch
											onChange={(e, value) => {
												onChange(`maximumWords.active`, value);
											}}
											checked={Boolean(inputs.maximumWords?.active)}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>
					{/* </MDBox> */}

					{/** Temperature */}
					{/* <MDBox flex="1" ml={1}> */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox mt={2}>
									<MDTypography variant="h6">{i18n.t("CONFIG.temperature")}</MDTypography>
									<MDBox>
										<MDBox mt={1}>
											<ThemeProvider theme={theme}>
												<Slider
													aria-label="Volume"
													defaultValue={inputs?.temperature?.value}
													step={0.1}
													min={0}
													max={2}
													valueLabelDisplay="auto"
													onChange={(e, value) => {
														onChange(`temperature.value`, value);
													}}
												/>
											</ThemeProvider>
											<MDBox display="flex" justifyContent="space-between">
												<MDTypography variant="caption">{i18n.t("CONFIG.uncrea")}</MDTypography>
												<MDTypography variant="caption">{i18n.t("CONFIG.crea")}</MDTypography>
											</MDBox>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
							<CardActions>
								<MDBox display="flex" justifyContent="space-between" alignItems="center">
									<span>{i18n.t("CONFIG.activeUser")}</span>
									<MDBox>
										<Switch
											onChange={(e, value) => {
												onChange(`temperature.active`, value);
											}}
											checked={Boolean(inputs.temperature?.active)}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>
					{/* </MDBox> */}
				</Grid>
				{/* </MDBox> */}
			</MDBox>
		</MDBox>
	);
};

export default GeneratereplyComponent;
