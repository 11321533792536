import { CircularProgress, Grid } from "@mui/material";
import MDBox from "components/Basics/MDBox";

export default function ChartsLoader({ darkMode }) {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<MDBox
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="60vh"
					sx={{ position: "relative" }}
				>
					<CircularProgress
						variant="determinate"
						sx={{
							color: theme => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
							animationDuration: "550ms",
							position: "absolute"
						}}
						size={60}
						thickness={4}
						value={100}
					/>
					<CircularProgress
						variant="indeterminate"
						disableShrink
						sx={{
							color: theme => (theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"),
							animationDuration: "550ms",
							position: "absolute"
						}}
						size={60}
						thickness={4}
					/>
				</MDBox>
			</Grid>
		</Grid>
	);
}
