/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
import lod_ from "lodash";
import { Google } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Icon } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { socket } from "redux-react/middleware/ws";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { wsEvent } from "redux-react/actions/ws";
import { t } from "i18next";

const GoogleReviewConfiguration = ({ config, setConfigPart }) => {
	/**
	 * Handle
	 * @param {*} accountId
	 */
	const handleAccountChange = accountId => {
		let copyConfig = lod_.cloneDeep(config);
		let account = copyConfig.google.accounts.find(acc => acc.id === accountId);
		let minOneIsInactive = false;
		account.locations.forEach(loc => {
			if (!loc.active) {
				minOneIsInactive = true;
			}
		});
		if (minOneIsInactive) {
			account.active = true;
			account.locations.forEach(loc => {
				loc.active = true;
			});
		} else {
			account.active = false;
			account.locations.forEach(loc => {
				loc.active = false;
			});
		}
		setConfigPart(copyConfig);
	};

	/**
	 * Handle the change of the location active status
	 * @param {*} accountId
	 * @param {*} locationId
	 */
	const handleLocationChange = (accountId, locationId) => {
		let copyConfig = lod_.cloneDeep(config);
		let account = copyConfig.google.accounts.find(acc => acc.id === accountId);
		let location = account.locations.find(loc => loc.id === locationId);
		location.active = !location.active;

		let minOneActive = false;
		account.locations.forEach(loc => {
			if (loc.active) {
				minOneActive = true;
			}
		});

		account.active = minOneActive;

		setConfigPart(copyConfig);
	};

	return (
		<MDBox>
			<MDBox sx={{ mt: 5, mb: 3 }}>
				<MDTypography variant="h5">{t("CHANNEL.GOOGLE.selectAccountsAndLocations")}</MDTypography>
				<MDTypography variant="overline">
					{t("CHANNEL.GOOGLE.selectAccountsAndLocationsDescription")}
				</MDTypography>
			</MDBox>
			{(config.google?.accounts ?? []).map((account, index) => (
				<MDBox key={index}>
					<FormControlLabel
						control={
							<Checkbox checked={account.active} onChange={e => handleAccountChange(account.id)} />
						}
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							userSelect: "none"
						}}
						label={
							<MDBox display="flex" flexDirection="row" alignItems="center">
								<Icon fontSize="medium">person</Icon>
								<MDTypography variant="body1" fontSize="medium" sx={{ ml: 1 }} fontWeight="bold">
									{account.name}
								</MDTypography>
							</MDBox>
						}
					/>
					{account.locations.length === 0 && (
						<MDBox ml={4}>
							<MDTypography variant="body2" fontSize="medium">
								{t("CHANNEL.GOOGLE.noBusinessFound")}
							</MDTypography>
						</MDBox>
					)}
					{account.locations.map((location, locIndex) => (
						<MDBox key={locIndex} ml={4}>
							<FormControlLabel
								control={
									<Checkbox
										checked={location.active}
										onChange={e => handleLocationChange(account.id, location.id)}
									/>
								}
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									userSelect: "none"
								}}
								label={
									<MDBox display="flex" flexDirection="row" alignItems="center">
										<Icon fontSize="medium">store_front</Icon>
										<MDTypography variant="body2" fontSize="medium" sx={{ ml: 1 }}>
											{`${location.name} (${location.locality ?? "Ville inconnue"})`}
										</MDTypography>
									</MDBox>
								}
							/>
						</MDBox>
					))}
				</MDBox>
			))}
		</MDBox>
	);
};

const GmailConfiguration = ({ config }) => {
	return (
		<MDBox sx={{ mt: 5, mb: 3 }}>
			<MDTypography variant="h5">{`Connecté en tant que`}</MDTypography>
			<MDTypography variant="body2" fontWeight="bold">{`${config?.google?.userEmail}`}</MDTypography>
		</MDBox>
	);
};

const GoogleConfiguration = ({
	googleConfig,
	channelCode,
	config,
	onChange,
	setConfigPart,
	type,
	googleType,
	dictionary,
	configSkeleton
}) => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const profile = useSelector(selectCurrentProfile);

	const googleTokenLoaded = config.google?.refreshToken;

	const { redirect_uri } = googleConfig || {};
	const { scopes, web } = googleConfig?.[googleType] || {};
	const { auth_uri, client_id } = web || {};

	const scope = scopes.join(" ");

	// State that will be passed to the google authentification
	const state = {
		ws: user.userID,
		action: "create",
		googleType: googleType,
		type: type,
		channelCode: channelCode,
		assistantID: profile.assistantID
	};

	// Query params for the google authentification
	const queryParams = new URLSearchParams({
		redirect_uri,
		scope,
		client_id,
		response_type: "code",
		access_type: "offline",
		prompt: "consent",
		state: JSON.stringify(state)
	}).toString();

	const url = `${auth_uri}?${queryParams}`;

	// Open the google authentification in a new tab
	const openAuthorizationIframe = () => {
		window.open(url, "_blank", "width=500,height=600").focus();
	};

	/**
	 * Result function of websocket event completeConfigChannelCreation
	 * Got when user creates a channel
	 * @param {*} { channel }
	 * @returns
	 */
	const completeConfigChannelCreation = ({ channel }) => {
		if (channel.code !== channelCode) {
			return;
		}

		let channelConfig = channel.config;

		setConfigPart(prev => {
			return {
				...prev,
				...channelConfig
			};
		});
	};

	/**
	 * Result function of websocket event loadAccountLocationOnChannelResult
	 * Refreshes the accounts and locations with the new data, keeping the active status
	 * @param {*} { accounts }
	 */
	const loadAccountsLocationsResult = ({ accounts }) => {
		for (let account of config?.google?.accounts ?? []) {
			let newAccount = accounts.find(acc => acc.id === account.id);
			if (newAccount) {
				newAccount.active = account.active;

				for (let location of account.locations) {
					let newLocation = newAccount.locations.find(loc => loc.id === location.id);
					if (newLocation) {
						newLocation.active = location.active;
					}
				}
			}
		}

		setConfigPart(prev => {
			return {
				...prev,
				google: {
					...prev.google,
					accounts: accounts
				}
			};
		});
	};

	const getConfigurationApp = () => {
		switch (googleType) {
			case "mybusiness":
				return <GoogleReviewConfiguration config={config} setConfigPart={setConfigPart} />;
			case "gmail":
				return <GmailConfiguration config={config} />;
			default:
				return null;
		}
	};

	useEffect(() => {
		// On component mount, refresh accounts and locations
		if (config?.google?.accounts) {
			dispatch(
				wsEvent({
					event: "load_account_location_google",
					payload: { channelCode }
				})
			);
		}

		// Create socket listeners
		socket.on("completeConfigChannelCreation", completeConfigChannelCreation);
		socket.on("loadAccountLocationOnChannelResult", loadAccountsLocationsResult);

		return () => {
			socket.off("completeConfigChannelCreation", completeConfigChannelCreation);
			socket.off("loadAccountLocationOnChannelResult", loadAccountsLocationsResult);
		};
	}, []);

	return (
		<MDBox sx={{ mt: 4, flex: 1 }}>
			{/* When user is not connected to google */}
			{!googleTokenLoaded && (
				<MDBox
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					style={{
						height: "100%"
					}}
				>
					<MDTypography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
						{t("CHANNEL.GOOGLE.connectToGoogleMessage")}
					</MDTypography>
					<MDButton color="info" onClick={openAuthorizationIframe}>
						<Google />
						<MDBox sx={{ ml: 2 }} color="white">{`Se connecter`}</MDBox>
					</MDButton>
				</MDBox>
			)}
			{/*
			 * For all google apps, Connected to Google, display configuratin
			 */}
			{googleTokenLoaded && (
				<MDBox>
					<MDBox
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<MDTypography variant="h5">{t("CHANNEL.GOOGLE.configureChannel")}</MDTypography>
						<MDButton color="info" onClick={openAuthorizationIframe}>
							<Google />
							<MDBox sx={{ ml: 2 }} color="white">
								{t("CHANNEL.GOOGLE.refreshAccountAccess")}
							</MDBox>
						</MDButton>
					</MDBox>
					<LittleForm
						object={dictionary}
						metadatasSkeleton={configSkeleton}
						handleChange={(path, value) => {
							onChange(path, value);
						}}
					/>
				</MDBox>
			)}
			{/*
			 * Part depending on the google app
			 */}
			{/* Connected to Google, display accounts & locations */}
			{googleTokenLoaded && getConfigurationApp()}
		</MDBox>
	);
};

export default GoogleConfiguration;
