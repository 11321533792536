/* eslint-disable no-restricted-syntax */
import "./style.css";
import lod_ from "lodash";
import { Checkbox, Icon, IconButton, InputAdornment, Menu } from "@mui/material";
import { useDispatch } from "react-redux";
import FormAction from "redux-react/actions/formAction";
import { useEffect, useState } from "react";
import i18n from "i18n";

import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import MDInput from "components/Basics/MDInput";

import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";

import ModelMenuItem from "./components/ModelMenuItem";
import ModelEdition from "./components/ModelEdition";
import { COLLECTIONS } from "../constants";

/**
 * Main component to add a process
 * @param {*} param0
 * @returns
 */
export default function PromptStudioModelPage({ route }) {
	const dispatch = useDispatch();

	// Filters
	const [sortedLlmModels, setSortedLlmModels] = useState([]);
	const [filtersModels, setFiltersModels] = useState([]);
	const [filterSearchModel, setFilterSearchModel] = useState("");
	const [filterMenu, setFilterMenu] = useState(null);

	// Step display
	const [stepDisplay, setStepDisplay] = useState(null);

	// Constants loaded from DB
	const [llmModels, setLlmModels] = useState([]);
	const [llmModelProvider, setLlmModelProvider] = useState([]);
	const [llmModelCategory, setLlmModelCategory] = useState([]);
	const [llmDocumentType, setLlmDocumentType] = useState([]);

	const sortLlmModels = models => {
		setLlmModels(models);
		let sortedModels = {};
		let items = models.sort((a, b) => {
			let aName = a.name.toLowerCase();
			let bName = b.name.toLowerCase();
			if (aName < bName) {
				return -1;
			}
			if (aName > bName) {
				return 1;
			}
			return 0;
		});

		for (let item of items) {
			let cat = sortedModels[item.documentType];
			if (cat) {
				cat.push(item);
			} else {
				sortedModels[item.documentType] = [item];
			}
		}

		setSortedLlmModels(sortedModels);
	};

	/* Get all LLM models / promptTemplates */
	const getConstVariables = () => {
		// Get all LLM models
		dispatch(
			FormAction.getItemsFromCollection(COLLECTIONS.llmModel, {}, res => {
				if (res.items) {
					sortLlmModels(res.items);
				}
			})
		);

		// Get model providers
		dispatch(
			FormAction.getItemsFromCollection(
				COLLECTIONS.llmModelProvider,
				{
					query: { assistantID: { $exists: false } },
					catalog: "AI"
				},
				res => {
					setLlmModelProvider(res.items);
				}
			)
		);

		// Get model categories
		dispatch(
			FormAction.getItemsFromCollection(
				COLLECTIONS.llmModelCategory,
				{
					query: { assistantID: { $exists: false } },
					sort: { rank: 1 },
					catalog: "AI"
				},
				res => {
					setLlmModelCategory(res.items);
				}
			)
		);

		// Get llm document type
		dispatch(
			FormAction.getItemsFromCollection(
				COLLECTIONS.llmDocumentType,
				{
					query: { assistantID: { $exists: false } },
					catalog: "AI"
				},
				res => {
					setLlmDocumentType(res.items);
				}
			)
		);
	};

	/* Refresh the list of models  */
	const updateModelList = code => {
		dispatch(
			FormAction.getItemsFromCollection(COLLECTIONS.llmModel, {}, res => {
				if (res.items) {
					sortLlmModels(res.items);
				}

				if (code) {
					setStepDisplay(code);
				} else {
					setStepDisplay(null);
				}
			})
		);
	};

	useEffect(() => {
		if (llmModels.length === 0) {
			getConstVariables();
		}
	}, []);

	/**
	 * Choose the correct display depending on the step
	 */
	const getCurrentDisplay = () => {
		switch (stepDisplay) {
			case null:
				return (
					<MDBox
						display="flex"
						alignItems="center"
						justifyContent="center"
						style={{ width: "100%", height: "100%", opacity: 0.7 }}
					>
						<MDTypography variant="h5">{i18n.t("PROMPT.MODEL.selectAModel")}</MDTypography>
					</MDBox>
				);
			case "new":
				return (
					<ModelEdition
						updateModelList={updateModelList}
						model={llmModels.find(model => model.code === stepDisplay)}
						llmModelProvider={llmModelProvider}
						llmModelCategory={llmModelCategory}
						llmDocumentType={llmDocumentType}
						newModel
					/>
				);
			default:
				return (
					<ModelEdition
						updateModelList={updateModelList}
						model={llmModels.find(model => model.code === stepDisplay)}
						llmModelProvider={llmModelProvider}
						llmModelCategory={llmModelCategory}
						llmDocumentType={llmDocumentType}
					/>
				);
		}
	};

	return (
		<DashboardLayout>
			<MDBox>
				<DashboardNavbar
					filters={[
						<MDBox sx={{ width: "50%" }} display="flex" alignItems="center">
							<MDButton
								variant="contained"
								color="dark"
								onClick={e => {
									setFilterMenu(e.currentTarget);
								}}
							>
								{i18n.t("PROMPT.MODEL.modelType")}
							</MDButton>
							<MDInput
								sx={{ ml: 1, flex: 1 }}
								placeholder={i18n.t("PROMPT.MODEL.searchAModel")}
								value={filterSearchModel}
								onChange={e => {
									setFilterSearchModel(e.target.value);
								}}
								// fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="clear"
												onClick={() => {
													setFilterSearchModel("");
												}}
											>
												<Icon>close</Icon>
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
							{/* Menu for filters */}
							<Menu
								id="simple-menu"
								anchorEl={filterMenu}
								open={Boolean(filterMenu)}
								onClose={() => {
									setFilterMenu(null);
								}}
								PaperProps={{
									style: {
										width: "300px"
									}
								}}
							>
								<MDBox>
									{llmDocumentType.map((type, i) => {
										return (
											<MDBox
												key={i}
												display="flex"
												alignItems="center"
												onClick={() => {
													if (filtersModels.includes(type.code)) {
														setFiltersModels(filtersModels.filter(f => f !== type.code));
													} else {
														setFiltersModels([...filtersModels, type.code]);
													}
												}}
												style={{
													cursor: "pointer",
													userSelect: "none"
												}}
											>
												<Checkbox checked={filtersModels.includes(type.code)} />
												<MDTypography fontSize="small" variant="body2">
													{type.name}
												</MDTypography>
											</MDBox>
										);
									})}
								</MDBox>
							</Menu>
						</MDBox>
					]}
				/>
			</MDBox>
			<MDBox
				mt={2}
				display="flex"
				flexDirection="row"
				borderRadius="lg"
				bgColor="white"
				sx={{
					height: "85vh"
				}}
				style={{
					overflow: "hidden"
				}}
			>
				{/* Left pannel */}
				<MDBox
					flex="2"
					display="flex"
					flexDirection="column"
					sx={{
						boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)"
					}}
				>
					{/* Top navbar process */}
					<MDBox
						display="flex"
						sx={{
							boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)"
						}}
					>
						<MDBox p={2} flex="1">
							<MDTypography variant="h5">{i18n.t("PROMPT.MODEL.listModels")}</MDTypography>
						</MDBox>
					</MDBox>
					<MDBox flex="1" style={{ overflowY: "auto" }}>
						{Object.keys(sortedLlmModels)
							/* Checkbox categories filter */
							.filter(key => {
								if (filtersModels.length === 0) {
									return true;
								} else if (filtersModels.includes(key)) {
									return true;
								}
								return false;
							})
							.map((key, i) => {
								return (
									<MDBox key={i} display="flex" flexDirection="column">
										{sortedLlmModels[key]
											/* Search input filter */
											.filter(model => {
												if (filterSearchModel === "") {
													return true;
												} else if (
													model.name.toLowerCase().includes(filterSearchModel.toLowerCase()) ||
													model.description
														.toLowerCase()
														.includes(filterSearchModel.toLowerCase()) ||
													model.code.toLowerCase().includes(filterSearchModel.toLowerCase())
												) {
													return true;
												}
												return false;
											})
											.map((model, i) => {
												return (
													<ModelMenuItem
														key={i}
														model={model}
														stepDisplay={stepDisplay}
														onClick={() => {
															setStepDisplay(model.code);
														}}
														className="clickablePannelItem"
														dbModel={llmModelProvider.find(
															provider =>
																provider.code === model.provider ||
																provider.code === model.modelProvider
														)}
														llmDocumentType={llmDocumentType}
													/>
												);
											})}
									</MDBox>
								);
							})}
					</MDBox>
					{/* Bottom navbar process */}
					<MDBox p={1} className="containerStudioDetails">
						<MDButton
							variant="contained"
							color="info"
							onClick={() => {
								setStepDisplay("new");
							}}
							style={{ width: "100%" }}
						>
							{i18n.t("PROMPT.MODEL.addAModel")}
						</MDButton>
					</MDBox>
				</MDBox>
				{/* Middle pannel */}
				<MDBox flex="6" display="flex">
					{getCurrentDisplay()}
				</MDBox>
			</MDBox>
		</DashboardLayout>
	);
}
