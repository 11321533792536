/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function configs(labels, datasets, options) {
	let legendDisplay = options?.legend !== undefined ? options.legend : true;

	return {
		data: {
			labels,
			datasets: [...datasets]
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: legendDisplay,
					position: datasets.length > 6 ? "bottom" : "left"
				},
				tooltip: {
					callbacks: {
						label: tooltipItem => {
							if (options.formatDate) {
								let value = parseFloat(tooltipItem.formattedValue.replaceAll(" ", ""));
								tooltipItem.formattedValue = formatMillis(value, "short", options.maxDateUnit);
							}
							if (tooltipItem.formattedValue === 0 || tooltipItem.formattedValue === "0") {
								return null;
							}
							return tooltipItem.dataset.label + " : " + tooltipItem.formattedValue;
						}
					}
				}
			},
			interaction: {
				intersect: false,
				mode: "index"
			},

			scales: {
				y: {
					grid: {
						drawBorder: false,
						display: true,
						drawOnChartArea: true,
						drawTicks: false,
						borderDash: [5, 5]
					},
					ticks: {
						display: true,
						padding: 10,
						color: "#b2b9bf",
						font: {
							size: 11,
							family: typography.fontFamily,
							style: "normal",
							lineHeight: 2
						},
						callback: value => {
							if (options.formatDate) {
								value = formatMillis(value, "short", options.maxDateUnit);
							}
							return value;
						},
						precision: 0
					}
				},
				x: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false,
						borderDash: [5, 5]
					},
					ticks: {
						display: true,
						color: "#b2b9bf",
						padding: 20,
						font: {
							size: 11,
							family: typography.fontFamily,
							style: "normal",
							lineHeight: 2
						}
					}
				}
			}
		}
	};
}

export default configs;

function formatMillis(s, format = "full", maxDateUnit = null) {
	if (s == 0) return "0 ms";
	// Pad to 2 or 3 digits, default is 2
	function pad(n, z = 2) {
		if (n == 0) return "";
		// return ("   " + n).slice(-z).trim();
		return ("   " + String(n).padStart(2, "0")).trim();
	}

	let mapper = { ms: 0, s: 1, m: 2, h: 3, d: 4, y: 5 };

	let nbr = 7; // Max number of element in the resulting string
	if (format === "simple") nbr = 3;

	let ms = maxDateUnit === "ms" ? s : s % 1000;
	s = (s - ms) / 1000;

	let secs = maxDateUnit === "s" ? s : s % 60;
	s = (s - secs) / 60;

	let mins = maxDateUnit === "m" ? s : s % 60;
	s = (s - mins) / 60;

	let hrs = maxDateUnit === "h" ? s : s % 24;
	s = (s - hrs) / 24;

	let days = maxDateUnit === "d" ? s : s % 365;
	s = (s - days) / 365;

	let years = s % 365;

	let res = "";

	let val = pad(years);
	if (val != "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.y)) {
		res += val + " y ";
		nbr--;
	}

	val = pad(days);
	if (val != "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.d)) {
		if (res !== "") nbr--;
		if (nbr > 0) res += val + " d ";
	}

	val = pad(hrs);
	if (val != "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.h)) {
		if (res !== "") nbr--;
		if (nbr > 0) res += val + " h ";
	}

	val = pad(mins);
	if (val != "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.m)) {
		if (res !== "") nbr--;
		if (nbr > 0) res += val + " mins ";
	}

	if (format == "short") {
		if (res === "") {
			return "< 1 min";
		}
		return res;
	}

	val = pad(secs);
	if (val != "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.s)) {
		if (res !== "") nbr--;
		if (nbr > 0) res += val + " s ";
	}

	val = pad(ms, 3);
	if (val != "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.ms)) {
		if (res !== "") nbr--;
		if (nbr > 0) res += val + " ms";
	}

	return res.trim();
}
