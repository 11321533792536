/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Material Dashboard 2 PRO React components
import MDInput from "components/Basics/MDInput";

function MDDatePicker({ input, ...rest }) {
	return (
		<Flatpickr
			{...rest}
			render={({ defaultValue }, ref) => (
				<MDInput {...input} defaultValue={defaultValue} inputRef={ref} />
			)}
		/>
	);
}

export default MDDatePicker;
