/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unreachable */
/**
 * #######################################################@
 *
 * Login page
 *
 * Mandatory page to chose profile to access application
 *
 * #######################################################@
 */
import "./style.css";
import Grid from "@mui/material/Grid";

import MDBox from "components/Basics/MDBox";

import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import { Card, CircularProgress, Slide } from "@mui/material";

import MDTypography from "components/Basics/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";
import lod_ from "lodash";
import { useEffect, useState } from "react";
import { initFilter } from "redux-react/reducers/filtersReducers";
import {
	setLoadingAssistant,
	resetLoadingAssistant
} from "redux-react/reducers/loadingAssistantReducer";

import { api } from "redux-react/actions/api";
import { display } from "redux-react/reducers/snackBarReducer";
import { setError } from "redux-react/reducers/errorReducer";
import ProfileActions from "redux-react/actions/profileActions";

const ItemCard = ({ role, name, active, sx = {}, style = {}, loading = false, onClick = null }) => {
	style = {
		...style,
		backgroundColor: active ? "#fff" : "#f5f5f5"
	};

	const dispatch = useDispatch();
	const [loadingState, setLoading] = useState(loading);

	const handleClick = () => {
		if (loadingState) return;
		if (onClick && active) {
			setLoading(true);
			onClick();
		}
	};

	return (
		<Card sx={sx} style={style} onClick={handleClick} className={active ? "clickable" : ""}>
			<MDBox p={2} display="flex" alignItems="center">
				<Grid container>
					<Grid item xs={7}>
						<MDBox mb={0.5} lineHeight={1}>
							<MDTypography
								variant="button"
								fontWeight="medium"
								color="text"
								textTransform="capitalize"
							>
								{i18n.t("ROLES." + role.toLowerCase())}
							</MDTypography>
						</MDBox>
						<MDBox lineHeight={1}>
							<MDTypography variant="h5" fontWeight="bold">
								{name}
							</MDTypography>
							{!active && (
								<MDTypography variant="body2" color="error">
									{i18n.t("LOGIN.inactive")}
								</MDTypography>
							)}
						</MDBox>
					</Grid>
				</Grid>

				{loadingState && (
					<Grid>
						<CircularProgress color="info" />
					</Grid>
				)}
			</MDBox>
		</Card>
	);
};

export default function Login() {
	const { assistants, user } = useSelector(state => state);
	const dispatch = useDispatch();

	async function selectProfile(assistant) {
		if (!assistant.active) {
			return;
		}

		dispatch(setLoadingAssistant(assistant));

		dispatch(
			api({
				type: "selectProfile",
				url: "/api/v1/profiles/" + assistant.assistantID,
				onSuccess: res => {
					dispatch(initFilter({ assistantID: res.profile.assistantID }));
					dispatch(ProfileActions.selectProfile(res, assistants, user));
					setTimeout(() => {
						dispatch(resetLoadingAssistant());
					}, 200);
				},
				onFailure: err => {
					dispatch(setError(err.response?.data?.error?.message ?? "none"));
					dispatch(resetLoadingAssistant());
				}
			})
		);
	}

	useEffect(() => {
		if (Object.keys(assistants).length === 1) {
			let assistant = assistants[Object.keys(assistants)[0]];
			selectProfile(assistant);
		}
	}, []);

	return (
		<DashboardLayout>
			{lod_.isEmpty(assistants) ? (
				<MDBox py={3}>
					<MDTypography variant="h4">{i18n.t("LOGIN.emptyAssistants")}</MDTypography>
				</MDBox>
			) : (
				<MDBox py={3}>
					<MDTypography variant="h4">{i18n.t("LOGIN.choice")}</MDTypography>
				</MDBox>
			)}
			{Object.keys(assistants).map((key, index) => {
				let assistant = assistants[key];
				return (
					<ItemCard
						sx={{ mt: 2 }}
						role={assistant.roleCode}
						name={assistant.name}
						active={assistant.active}
						onClick={() => {
							selectProfile(assistant);
						}}
						key={index}
					/>
				);
			})}
		</DashboardLayout>
	);
}
