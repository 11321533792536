/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";

import "./style.css";
import { createRef, useEffect } from "react";
import { useMaterialUIController } from "context";

function DataTableBodyCell({
	noBorder = false,
	align = "left",
	children,
	maximumFirstCellWidth = null,
	setMaximumFirstCellWidth = null,
	...rest
}) {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	let realClass = `classicCell_${darkMode ? "dark" : "light"}`;
	if (rest.className) {
		realClass += ` ${rest.className}`;
	}

	if (rest.static) {
		realClass += " tableCellFlex";
	}

	let ref = createRef();

	useEffect(() => {
		setTimeout(() => {
			if (ref.current && setMaximumFirstCellWidth) {
				const boundingRect = ref.current.getBoundingClientRect();
				setMaximumFirstCellWidth(width => {
					if (!width || width < boundingRect.width) {
						width = boundingRect.width;
					}

					return width;
				});
			}
		});
	}, [ref]);

	let style = {};

	style = {
		...style,
		...rest.style
	};

	if (maximumFirstCellWidth) {
		style.width = `${maximumFirstCellWidth}px`;
	}

	return (
		<MDBox
			ref={ref}
			className={realClass}
			component="td"
			textAlign={align}
			py={1.5}
			px={3}
			sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
				fontSize: size.sm,
				borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`
			})}
			style={style}
		>
			<MDBox
				display="inline-block"
				width="max-content"
				color="text"
				sx={{ verticalAlign: "middle" }}
			>
				{children}
			</MDBox>
		</MDBox>
	);
}

export default DataTableBodyCell;
