/* eslint-disable no-debugger */
/* eslint-disable import/order */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import lod_ from "lodash";
import ChartsActions from "redux-react/actions/chartsActions";
import { Card, Fab, Fade, Icon, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";
import { display } from "redux-react/reducers/snackBarReducer";
import { Save } from "@mui/icons-material";
import i18n from "i18n";
import { createDictionarySkeleton } from "helpers/form";

/**
 * Component to display settings from "ruleConfig", and edit them
 * @param {*} props
 * @returns
 */
export default function SettingsComponent(props) {
	const [settingsCopy, setSettingsCopy] = useState({});
	const [settings, setSettings] = useState(lod_.cloneDeep(props.settings));
	const dispatch = useDispatch();
	const [canSaveChanges, setCanSaveChanges] = useState(false);
	const profile = useSelector(selectCurrentProfile);
	const [outputSkeleton, setOutputSkeleton] = useState({});
	const [outputDictionary, setOutputDictionary] = useState(props.dictionary);

	/* Save updated settings */
	const handleSave = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.SEETINGSADMIN.CALL.successUpdate"),
					type: "success"
				})
			);
		};
		dispatch(
			ChartsActions.updateSettings(
				profile.assistantID,
				props.collection,
				outputSkeleton,
				props.settings._id,
				onSuccess
			)
		);

		setSettingsCopy(lod_.cloneDeep(outputSkeleton));
	};

	const [tab, setTab] = useState(0);

	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(outputSkeleton);
		lod_.set(clone, path, value);
		setOutputSkeleton(clone);
	};

	useEffect(() => {
		let skeleton = createDictionarySkeleton(outputDictionary, settings);

		setOutputSkeleton(skeleton);
		setSettingsCopy(skeleton);
	}, []);

	useEffect(() => {
		if (!lod_.isEqual(outputSkeleton, settingsCopy)) {
			setCanSaveChanges(true);
		} else {
			setCanSaveChanges(false);
		}
	}, [outputSkeleton, settingsCopy]);

	return (
		<>
			<Card>
				<MDBox p={3}>
					{/* Top row : name / actions buttons */}
					<MDBox display="flex" alignItems="center" justifyContent="space-between" mb={4}>
						<MDBox display="flex" alignItems="center">
							<MDTypography variant="h2">{props.title}</MDTypography>
						</MDBox>
					</MDBox>
					{/* Content */}
					{tab === 0 && (
						<MDBox>
							<LittleForm
								object={outputDictionary}
								metadatasSkeleton={outputSkeleton}
								listAPIs={props.listAPIs}
								handleChange={handleChange}
							/>
						</MDBox>
					)}
				</MDBox>
			</Card>
			<Fade in={canSaveChanges}>
				<div
					style={{
						position: "fixed",
						bottom: 20,
						right: 20,
						zIndex: 1000
					}}
				>
					<Fab variant="extended" color="info" onClick={handleSave}>
						<MDTypography variant="h6" color="light">
							{i18n.t("SETTINGS.RULES.detectedModifications")}
						</MDTypography>
						<MDBox
							bgColor="light"
							p={1}
							borderRadius="lg"
							fontWeight="bold"
							display="flex"
							alignItems="center"
							ml={2}
						>
							<Save sx={{ mr: 1 }} />
							&nbsp;{i18n.t("SETTINGS.save")}
						</MDBox>
					</Fab>
				</div>
			</Fade>
		</>
	);
}
