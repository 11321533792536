import MDBadge from "components/Basics/MDBadge";
import { t } from "i18next";

const { default: MDBox } = require("components/Basics/MDBox");
const { default: MDTypography } = require("components/Basics/MDTypography");

const { MESSAGE_TYPE } = require("../../../../constants");

/**
 * Container for one message in left pannel
 */
const MessageMenuItem = ({ state, list, index, onClick, stepDisplay, message, ...rest }) => {
	// Find the correct type of message
	let correctType = MESSAGE_TYPE.find(type => type.code === message.type);
	return (
		<MDBox onClick={onClick} display="flex" alignItems="stretch" {...rest}>
			<MDBox
				bgColor={stepDisplay === list ? "info" : "white"}
				style={{
					width: "10px"
				}}
			></MDBox>
			<MDBox flex="1" p={2} display="flex" justifyContent="space-between">
				<MDBox display="flex" flexDirection="column" flex="1">
					<MDTypography variant="h6">
						{list === "system_message"
							? t("PROMPT.PROCESS.MESSAGE.systemMessage")
							: `${t("PROMPT.PROCESS.MESSAGE.message")} #${index + 1}`}
					</MDTypography>
					<MDBadge
						circular
						color={list === "system_message" ? "info" : "info"}
						variant="contained"
						size="xs"
						badgeContent={correctType?.label}
						container
					></MDBadge>
					<MDTypography
						variant="body2"
						fontSize="small"
					>{`${state[list].length} parties`}</MDTypography>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default MessageMenuItem;
