/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unreachable */
import "./style.css";
import "../../style.css";
import lod_ from "lodash";
import {
	Icon,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip
} from "@mui/material";
import { useState, useEffect } from "react";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import i18n from "i18n";
import AdvancedInput from "components/Custom/AdvancedInput";
import { grey } from "@mui/material/colors";
import InputBoolean from "components/Custom/LittleForm/Inputs/InputBoolean";
import InputPhone from "components/Custom/LittleForm/Inputs/InputPhone";
import InputDate from "components/Custom/LittleForm/Inputs/InputDate";
import InputNumber from "components/Custom/LittleForm/Inputs/InputNumber";
import { useDispatch } from "react-redux";
import { queryDictionaries } from "helpers/dictionary";

/**
 * Get the path's label for a dictionary
 * @param {*} dictionary
 * @param {*} path
 * @returns
 */
const getPath = (dictionary, path) => {
	if (!path) {
		return i18n.t("CHANNEL.MAPPING.undefined");
	}

	const parts = path.split(".");
	const result = [];
	let current = "";

	for (let i = 0; i < parts.length; i++) {
		current += parts[i];
		result.push(current);
		current += ".items.";
	}

	let stringPath = "";

	for (let i = 0; i < result.length; i++) {
		stringPath += " " + lod_.get(dictionary, result[i] + ".label.fr") + " /" || "";
	}

	stringPath = stringPath.slice(0, -1);
	return stringPath;
};

/**
 * Mapping row
 * @param {*} param0
 * @returns
 */
const MappingRow = ({
	line,
	index,
	inputDictionaries,
	outputDictionaries,
	updateMappingRow,
	deleteMappingRow
}) => {
	const [anchorElOutput, setAnchorElOutput] = useState(null);
	const [anchorElInput, setAnchorElInput] = useState(null);

	// Type can be : singleValue, customValue
	const onChange = (key, value, type = "singleValue") => {
		updateMappingRow(index, key, line.key, value, type);
	};

	let dictionaryInputItem = lod_.get(outputDictionaries, line.key?.replaceAll(".", ".items."));
	let lineType = dictionaryInputItem?.type;

	const keyItem = lod_.get(outputDictionaries, line.key?.replaceAll(".", ".items."));

	const getValueEditor = () => {
		switch (line.type) {
			// Single value, return field display
			case "singleValue":
			case null:
			case undefined:
				return getPath(inputDictionaries, line.value);
			// Custom value, return advanced input
			case "customValue":
				switch (lineType) {
					case "string":
					case "email":
						return (
							<AdvancedInput
								value={line.value}
								dictionary={inputDictionaries}
								onChange={e => onChange(line.key, e, "customValue")}
								style={{
									borderRadius: "0.375rem 0 0 0.375rem"
								}}
							/>
						);
					case "boolean":
						return (
							<InputBoolean
								value={line.value}
								onChange={e => onChange(line.key, e, "customValue")}
								InputProps={{
									borderRadius: "0.375rem 0 0 0.375rem",
									height: "100%",
									margin: 0
								}}
							/>
						);
					case "phone":
						return (
							<InputPhone
								value={line.value}
								onChange={e => onChange(line.key, e, "customValue")}
								InputProps={{
									style: {
										borderRadius: "0.375rem 0 0 0.375rem",
										height: "100%",
										margin: 0
									}
								}}
								style={{
									height: "100%",
									margin: 0
								}}
							/>
						);
					case "date":
					case "datetime":
						return (
							<InputDate
								fullWidth
								value={line.value}
								label={!line.value ? "Choisir une date" : ""}
								onChange={e => onChange(line.key, e.toISOString(), "customValue")}
								InputProps={{
									className: "faibrikCustomFieldsetInput",
									style: {
										borderRadius: "0.375rem 0 0 0.375rem",
										border: "1px solid #d2d6da",
										height: "100%",
										margin: 0
									}
								}}
								style={{
									height: "100%",
									margin: 0
								}}
							/>
						);
					case "number":
						return (
							<InputNumber
								label={!line.value ? "Renseigner un nombre" : ""}
								value={line.value}
								onChange={e => onChange(line.key, e, "customValue")}
								InputProps={{
									fullWidth: true,
									className: "faibrikCustomFieldsetInput",
									style: {
										borderRadius: "0.375rem 0 0 0.375rem",
										border: "1px solid #d2d6da",
										height: "100%",
										margin: 0
									}
								}}
								style={{
									height: "100%",
									margin: 0
								}}
							/>
						);
					default:
						return getPath(inputDictionaries, line.value);
				}

			default:
				return "EDITOR";
		}
	};

	return (
		<MDBox component="tr">
			{/* Output */}
			<TableCell style={{ border: "none" }} width="50%">
				<MDBox
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="stretch"
				>
					<MDBox
						flex="1"
						className="boxInputStyle"
						borderRadius="md"
						style={{
							borderRadius: "0.375rem 0 0 0.375rem"
						}}
					>
						{getPath(outputDictionaries, line.key)}
					</MDBox>
					<MDBox
						className="endButtonboxInputStyle"
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="light"
						onClick={e => setAnchorElOutput(e.target)}
					>
						<Icon fontSize="small">menu</Icon>
					</MDBox>
					<DictionaryMenu
						placement="right"
						dictionary={outputDictionaries}
						anchorEl={anchorElOutput}
						handleInsertText={e => {
							setAnchorElOutput(null);
							onChange(e, line.value);
						}}
						handleClose={() => setAnchorElOutput(null)}
					/>
				</MDBox>
			</TableCell>
			{/* Mid cell */}
			<TableCell style={{ border: "none" }}>
				<MDBox display="flex" flexDirection="row" justifyContent="center" alignItems="center">
					<Icon color={line.valid ? "success" : "error"} fontSize="medium">
						arrow_backward
					</Icon>
				</MDBox>
			</TableCell>
			{/* Input */}
			<TableCell style={{ border: "none" }} width="50%">
				<MDBox
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="stretch"
				>
					<MDBox
						flex="1"
						className={line.type === "customValue" ? "" : "boxInputStyle"}
						borderRadius="md"
						style={{
							borderRadius: "0.375rem 0 0 0.375rem"
						}}
					>
						{getValueEditor()}
					</MDBox>
					<Tooltip title="Valeur personnalisée" placement="top">
						<MDBox
							className="endButtonboxInputStyle"
							display="flex"
							justifyContent="center"
							alignItems="center"
							bgColor={line.type === "customValue" ? "dark" : "light"}
							style={{
								borderRadius: "0"
							}}
							onClick={() => onChange(line.key, line.value, "customValue")}
						>
							<Icon
								style={{
									color: line.type === "customValue" ? grey[50] : "inherit"
								}}
								fontSize="small"
							>
								edit
							</Icon>
						</MDBox>
					</Tooltip>
					<MDBox
						className="endButtonboxInputStyle"
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="light"
						onClick={e => setAnchorElInput(e.target)}
					>
						<Icon fontSize="small">menu</Icon>
					</MDBox>
					<DictionaryMenu
						placement="right"
						dictionary={inputDictionaries}
						anchorEl={anchorElInput}
						handleInsertText={e => {
							setAnchorElInput(null);
							onChange(line.key, e);
						}}
						handleClose={() => setAnchorElInput(null)}
						// restrictedTypes={keyItem ? [keyItem.type] : null}
					/>
				</MDBox>
			</TableCell>
			{/* Actions */}
			<TableCell style={{ padding: 0, border: "none" }}>
				<IconButton onClick={() => deleteMappingRow(index, line.key)}>
					<Icon fontSize="medium">delete</Icon>
				</IconButton>
			</TableCell>
		</MDBox>
	);
};

/**
 * Step 4: Mapping
 */
const Step4Mapping = ({
	validStep,
	channelSubType = {},
	mapping,
	mappingObject,
	setMapping,
	setMappingObject
}) => {
	const dispatch = useDispatch();

	const [inputDictionaries, setInputDictionaries] = useState({});
	const [outputDictionaries, setOutputDictionaries] = useState({});

	const setupDictionaries = async () => {
		let inputDictionariesKeys = channelSubType.dictionariesInput ?? [];
		let outputDictionariesKeys = channelSubType.dictionariesOutput ?? [];

		const inputDictionariesList = await queryDictionaries(dispatch, inputDictionariesKeys);
		const outputDictionariesList = await queryDictionaries(dispatch, outputDictionariesKeys);

		setInputDictionaries(inputDictionariesList);
		setOutputDictionaries(outputDictionariesList);
	};

	useEffect(() => {
		setupDictionaries();
	}, []);

	const addNewEntry = () => {
		setMapping([
			...mapping,
			{
				key: null,
				value: null,
				valid: false
			}
		]);
	};

	const updateMappingRow = (index, key, previousKey, value, type) => {
		// 1- Trying to set a key that already exists in the mapping => do nothing
		let existingKey = lod_.get(mappingObject, key);

		let valueExists = value !== null && value !== undefined;

		let existingIndex = mapping.findIndex(row => row.key === key);

		if (/* existingKey !== undefined && */ existingIndex !== -1 && index !== existingIndex) {
			return;
		}

		// 2- Update the mapping object when the key and value are valid
		if (key && valueExists) {
			let clonedMappingObject = lod_.cloneDeep(mappingObject);
			lod_.set(clonedMappingObject, key, value);

			// 2.1- If the key is different from the previous one, we need to remove the previous key
			if (key !== previousKey) {
				lod_.unset(clonedMappingObject, previousKey);
				clonedMappingObject.value = null;
			}

			setMappingObject(prev => {
				return clonedMappingObject;
			});
		}

		// 3- Update the mapping row state
		let clonedMapping = lod_.cloneDeep(mapping);
		let clonedRow = clonedMapping[index];
		clonedRow.key = key;
		clonedRow.value = key !== previousKey ? null : value;
		clonedRow.valid = key && valueExists;
		clonedRow.type = type;
		setMapping(clonedMapping);
	};

	const deleteMappingRow = (index, key) => {
		let clonedMapping = lod_.cloneDeep(mapping);
		clonedMapping.splice(index, 1);
		setMapping(clonedMapping);
		if (key) {
			let clonedMapping = lod_.cloneDeep(mappingObject);
			lod_.unset(clonedMapping, key);
			setMappingObject(clonedMapping);
		}
	};

	useEffect(() => {
		validStep();
	}, []);

	return (
		<MDBox sx={{ height: "100%", width: "100%" }} display="flex" justifyContent="center">
			<MDBox sx={{ width: "100%" }}>
				<MDBox pt={3} pb={3} display="flex" justifyContent="end">
					<MDButton variant="contained" color="info" onClick={addNewEntry}>
						<Icon>add</Icon>&nbsp;{i18n.t("CHANNEL.MAPPING.newEntry")}
					</MDButton>
				</MDBox>

				<Table>
					<TableHead
						style={{
							display: "contents"
						}}
					>
						<TableRow>
							<TableCell>{i18n.t("CHANNEL.MAPPING.mappingOutput")}</TableCell>
							<TableCell></TableCell>
							<TableCell>{i18n.t("CHANNEL.MAPPING.mappingInput")}</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{mapping.map((line, index) => {
							return (
								<MappingRow
									line={line}
									key={index}
									index={index}
									inputDictionaries={inputDictionaries}
									outputDictionaries={outputDictionaries}
									updateMappingRow={updateMappingRow}
									deleteMappingRow={deleteMappingRow}
								/>
							);
						})}
					</TableBody>
				</Table>
			</MDBox>
		</MDBox>
	);
};

export default Step4Mapping;
