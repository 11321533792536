/* eslint-disable prefer-destructuring */
import { Card, Grid } from "@mui/material";
import {
	LoadingComplexStatisticsCard,
	ComplexStatisticsCard
} from "components/Advanced/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "components/Basics/MDBox";
import TemperatureCard from "components/Custom/TemperatureCard";

const OneValueChart = ({ chart, data, ...rest }) => {
	if (!data) {
		return (
			<Grid item {...rest}>
				<MDBox mb={1.5}>
					<LoadingComplexStatisticsCard
						icon={chart.display.icon}
						title={chart.display.title}
						text="Chargement des données"
					/>
				</MDBox>
			</Grid>
		);
	} else if (data.length === 0) {
		return (
			<Grid item {...rest}>
				<MDBox mb={1.5}>
					<ComplexStatisticsCard
						icon={chart.display.icon}
						title={chart.display.title}
						count={`0 ${chart.display.label ?? ""}`}
						percentage={{}}
					/>
				</MDBox>
			</Grid>
		);
	}

	/**
	 * Data must be an array with always one only element
	 */
	data = data[0];
	let percentage = {};

	if (data.total_ratio) {
		percentage = {
			color: parseFloat(data.total_ratio) > 0 ? "success" : "error",
			amount: parseFloat(data.total_ratio) > 0 ? `+ ${data.total_ratio} %` : `${data.total_ratio} %`,
			label: "par rapport à la période précédente"
		};
	}

	switch (chart.display.subType) {
		case "card":
			return (
				<Grid item {...rest}>
					<MDBox mb={1.5}>
						<ComplexStatisticsCard
							icon={chart.display.icon}
							title={chart.display.title}
							count={`${data.total} ${chart.display.label ?? ""}`}
							percentage={percentage}
						/>
					</MDBox>
				</Grid>
			);
		case "temperature":
			return (
				<Grid item {...rest}>
					<MDBox mb={1.5}>
						<TemperatureCard
							icon={chart.display.icon}
							title={chart.display.title}
							count={`${data.total} ${chart.display.label ?? ""}`}
							percentage={percentage}
						/>
					</MDBox>
				</Grid>
			);
		default:
			return (
				<Grid item {...rest}>
					<MDBox mb={1.5}>
						<ComplexStatisticsCard
							icon={chart.display.icon}
							title={chart.display.title}
							count={`${data.total} ${chart.display.label ?? ""}`}
							percentage={percentage}
						/>
					</MDBox>
				</Grid>
			);
	}
};

export default OneValueChart;
