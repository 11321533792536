/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import SettingsActions from "redux-react/actions/settingsActions";
/**
 * Query a dictionary with promise
 * @param {string} code - Code of the dictionary
 * @returns {Promise} - Promise with the dictionary
 */
export async function queryDictionary(dispatch, code) {
	return new Promise((resolve, reject) => {
		dispatch(
			SettingsActions.getDictionary(code, res => {
				resolve(res.dictionary);
			})
		);
	});
}

/**
 * Query dictionaries from the API
 * @param {<string>} value
 * @returns {Promise} - Promise with the dictionaries
 */
export async function queryDictionaries(dispatch, codes) {
	const dictionaries = {};

	for (let codeDictionary of codes) {
		let dictionary = await queryDictionary(dispatch, codeDictionary);
		dictionaries[codeDictionary] = dictionary;
	}

	return dictionaries;
}
