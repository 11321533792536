import { Avatar } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
/**
 * Information card : Title / Description / Image, click handler
 */
const InformationCardUser = ({ onClick, selected, element }) => {
	return (
		<MDBox
			m={1}
			mt={1}
			className="cardGraphChoice"
			onClick={onClick}
			p={3}
			borderRadius="xl"
			style={{
				backgroundColor: selected ? "#1A73E8" : ""
			}}
			sx={{
				transition: "background-color 0.1s ease-in-out",
				"&:hover": {
					backgroundColor: "#eeeeee"
				}
			}}
		>
			<MDBox display="flex" alignItems="center">
				<MDBox paddingRight="2%">
					<Avatar
						style={{ width: 100, height: 100, objectFit: "cover" }}
						alt="profile"
						src={element.picture}
					/>
				</MDBox>
				<MDBox flex="1">
					<MDTypography variant="h5" color={selected ? "white" : "dark"}>
						{i18n.t(element.userID)}
					</MDTypography>
					<MDTypography variant="body2" color={selected ? "white" : "dark"}>
						{i18n.t(element.first)} {i18n.t(element.last)}
					</MDTypography>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default InformationCardUser;
