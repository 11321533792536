import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDButton from "components/Basics/MDButton";
import MDInput from "components/Basics/MDInput";
import { t } from "i18next";
import { useState } from "react";

const DuplicatePageDialog = ({ dialog, setDialog, onSave }) => {
	const [pageName, setPageName] = useState("");

	const onClose = () => {
		setDialog({ open: false, page: {} });
	};

	const submit = () => {
		onClose();
		onSave(dialog.page, pageName);
	};

	return (
		<Dialog open={dialog.open} fullWidth maxWidth="md">
			<DialogTitle>{`Dupliquer la page "${dialog.page.name}"`}</DialogTitle>
			<DialogContent>
				<MDInput
					value={pageName}
					onChange={e => setPageName(e.target.value)}
					sx={{ mt: 1 }}
					fullWidth
					label="Nom de la nouvelle page"
				/>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={onClose}>
					{t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={!pageName} variant="contained" color="info" onClick={submit}>
					{t("SETTINGS.save")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

export default DuplicatePageDialog;
