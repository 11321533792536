/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Labels settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	ClickAwayListener,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	Paper,
	Popper
} from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { ChromePicker, TwitterPicker } from "react-color";
import i18n from "i18n";

export default function EditLabelDialog({ open, handleCloseDialog, handleSave, label }) {
	const [code, setCode] = useState(null);
	const [labelName, setLabelName] = useState(null);
	const [color, setColor] = useState({ hex: "#222" });
	const [pickerRef, setPickerRef] = useState(null);

	const isDisabled = !code || !labelName || !color;

	function handleColorSelection(color) {
		setColor(color);
	}

	function close(force = false) {
		if (pickerRef) {
			setPickerRef(null);
			if (!force) return;
		}
		handleCloseDialog();
		setCode(null);
		setLabelName(null);
		setColor({ hex: "#222" });
		setPickerRef(null);
	}

	function sumbit() {
		if (!code || !labelName || !color) return;
		handleSave({
			code,
			label: labelName,
			color: color.hex
		});
		close(true);
	}

	function displayPicker(e) {
		setPickerRef(e.target);
	}

	useEffect(() => {
		setCode(label.code);
		setLabelName(label.label);
		setColor({ hex: label.color });
	}, [label]);

	return (
		<Dialog fullWidth maxWidth="md" open={open} onClose={() => close()}>
			<DialogTitle>{i18n.t("SETTINGS.LABELS.edit")}</DialogTitle>
			<DialogContent>
				<MDBox mt={1}>
					<MDInput
						className="dialogInput"
						label={i18n.t("SETTINGS.LABELS.code")}
						value={code || ""}
						onChange={e => setCode(e.target.value)}
						disabled
					/>
				</MDBox>
				<MDBox mt={1}>
					<MDInput
						className="dialogInput"
						label={i18n.t("SETTINGS.LABELS.label")}
						value={labelName || ""}
						onChange={e => setLabelName(e.target.value)}
					/>
				</MDBox>
				<MDBox mt={1} className="colorContainer">
					<MDBox className="colorPreset">
						<TwitterPicker
							width="100%"
							triangle="hide"
							color={color}
							onChange={handleColorSelection}
						/>
					</MDBox>
					<MDBox
						className="buttonColorPicker"
						style={{
							backgroundColor: color.hex
						}}
						onClick={displayPicker}
					>
						<Icon fontSize="medium" className="buttonColorPickerIcon">
							colorize
						</Icon>
					</MDBox>
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={() => close()}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={isDisabled} variant="contained" color="dark" onClick={sumbit}>
					{i18n.t("SETTINGS.edit")}
				</MDButton>
			</DialogActions>
			<Popper
				id={pickerRef ? "simple-popper" : ""}
				open={Boolean(pickerRef)}
				anchorEl={pickerRef}
				placement="right"
				style={{
					zIndex: 10000
				}}
			>
				{() => (
					<ClickAwayListener
						onClickAway={e => {
							setPickerRef(null);
						}}
					>
						<Paper>
							<ChromePicker disableAlpha color={color} onChange={handleColorSelection} />
						</Paper>
					</ClickAwayListener>
				)}
			</Popper>
		</Dialog>
	);
}
