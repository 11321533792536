/* eslint-disable object-shorthand */
import MDBox from "components/Basics/MDBox";
import MDDatePicker from "components/Basics/MDDatePicker";
import Languages from "flatpickr/dist/l10n/index";
import i18n from "i18n";

const InputDate = ({ label, value, onChange, disabled, error, InputProps = {}, ...rest }) => {
	const DefaultLanguage = Languages[i18n.language] || Languages.en;

	return (
		<MDBox mt={1} {...rest}>
			<MDDatePicker
				type="date"
				label={label}
				value={value}
				onChange={(dateObj, dateStr, instance) => {
					onChange(dateObj[0]);
				}}
				options={{
					time_24hr: true,
					dateFormat: "d M Y",
					locale: {
						...DefaultLanguage,
						firstDayOfWeek: 1
					}
				}}
				input={{
					label: label,
					placeholder: `${label} `,
					...InputProps,
					style: {
						width: "100%",
						...InputProps.style
					}
				}}
				{...rest}
				disabled={disabled}
				onDayCreate={(dObj, dStr, fp, dayElem) => {
					if (dayElem.dateObj.toDateString() === new Date().toDateString()) {
						dayElem.className += " today-date";
					}
				}}
			/>
		</MDBox>
	);
};
export default InputDate;
