/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import { useState, useEffect } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Sidenav from "components/Advanced/Sidenav";
import Configurator from "components/Advanced/Configurator";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";

import Router from "routes/routes";

import { useMaterialUIController, setMiniSidenav } from "context";

import lod_ from "lodash";

import myLogo from "assets/images/logo.png";

import "assets/style/app.css";
import "assets/style/error.css";
import "assets/style/navbar.css";
import { useSelector } from "react-redux";
import Login from "pages/login";

import "./assets/fonts/Cedora-Bold.otf";
import "./assets/fonts/Cedora-Regular.otf";
import SnackBarMessage from "components/Custom/SnackBar";
import { buildCustomRoutes } from "routes/utils";
import { CircularProgress, Slide } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

/**
 * @param {*} permissionsExpected The permissions expected to access the element
 * @param {*} permissionsGiven The permissions of the user
 * @returns
 */
export const hasPermissions = (permissionsExpected, permissionsGiven) => {
	if (!permissionsExpected || lod_.isEmpty(permissionsExpected)) return true;

	let hasPermission = false;

	for (let perm of permissionsExpected) {
		if (permissionsGiven.includes(perm)) {
			hasPermission = true;
			break;
		}
	}
	return hasPermission;
};

export default function App() {
	const [controller, dispatch] = useMaterialUIController();
	const { miniSidenav, darkMode } = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const { pathname } = useLocation();
	const { profile, permissions, loadingAssistant } = useSelector(state => state);
	const [routes, setRoutes] = useState([]);

	const brandName = profile?.selectedAssistant?.name ?? process.env.REACT_APP_APPNAME?.toUpperCase();

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	/**
	 * Get all routes
	 */
	const getRoutes = (allRoutes, routes = []) => {
		for (let i = 0; i < allRoutes.length; i++) {
			let route = allRoutes[i];

			if (route.permissions) {
				if (!hasPermissions(route.permissions, permissions.permissions)) {
					continue;
				}
			}

			if (route.routes) {
				getRoutes(route.routes, routes);
			}

			if (route.collapse) {
				getRoutes(route.collapse, routes);
			} else if (route.route && route.type !== "hidden") {
				routes.push(<Route exact path={route.route} element={route.component} key={i} />);
			}
		}

		return routes;
	};

	/**
	 * Get the redirect route
	 */
	function getRedirectRoute(routes) {
		let redirectRoute = routes.find(route => {
			if (!route.route) {
				return false;
			}

			if (["/login", "/logout"].includes(route.route)) {
				return false;
			}

			if (route.type === "system") {
				return false;
			}

			if (!hasPermissions(route.permissions, permissions.permissions)) {
				return false;
			}

			return true;
		});

		if (redirectRoute) {
			return <Route path="*" element={<Navigate to={redirectRoute.route} />} />;
		}

		return null;
	}

	/**
	 * If user is logged, he needs to chose a profile before to be redirect to dashboard
	 */
	function router() {
		if (!lod_.isEmpty(profile)) {
			return (
				<>
					{getRoutes(routes)}
					{getRedirectRoute(routes)}
				</>
			);
		} else {
			return (
				<>
					<Route exact path="/login" element={<Login />} key="login" />
					<Route path="*" element={<Navigate to="/login" />} />
				</>
			);
		}
	}

	/**
	 * Merge basic routes and custom routes
	 */
	useEffect(() => {
		if (!lod_.isEmpty(profile)) {
			let basicRoutes = Router();
			let customRoutes = profile?.selectedAssistant?.menu ?? [];
			let newRoutes = [...basicRoutes, ...customRoutes];
			newRoutes = buildCustomRoutes(newRoutes);
			setRoutes(newRoutes);
		}
	}, [profile]);

	/**
	 * Scroll to top of the page when changing the route
	 */
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	return (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<CssBaseline />
			<>
				<SnackBarMessage />
				<Sidenav
					brandName={brandName}
					brand={myLogo}
					routes={routes}
					onMouseEnter={handleOnMouseEnter}
					onMouseLeave={handleOnMouseLeave}
				/>
				<Configurator />
			</>
			<Routes>{router()}</Routes>

			<Slide direction="up" in={!lod_.isEmpty(loadingAssistant)} mountOnEnter unmountOnExit>
				<MDBox bgColor="light" className="loadingBox" display="flex" flexDirection="column">
					<MDBox display="flex" justifyContent="center" alignItems="center">
						<img src={myLogo} alt="logo" width="15%" />
					</MDBox>
					{loadingAssistant?.name && (
						<MDBox mt={10}>
							<MDTypography color="dark" variant="h4" fontWeight="bold">
								{`Connexion sur l'assistant "${loadingAssistant?.name}"`}
							</MDTypography>
						</MDBox>
					)}

					<MDBox mt={5}>
						<CircularProgress color="dark" />
					</MDBox>
				</MDBox>
			</Slide>
		</ThemeProvider>
	);
}
