import lod_ from "lodash";
import { ExpandMore } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Chip,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Tooltip
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import { useState } from "react";

/**
 * Right pannel for dynamic list
 * @param {*} param0
 * @returns
 */
const PannelDynamicList = ({ values, handleInputChange, listAPIs, PI }) => {
	const [stringWarnAddWD, setStringWarnAddWD] = useState("");
	const [showWarningWD, setShowWarningWD] = useState(false);

	const updateDynamic = e => {
		handleInputChange(e);
	};

	let onChangeDynamicTag = (child, list) => {
		if (!list.includes(values?.whitelistDynamic?.params?.attribute)) {
			let e = { target: { name: child, value: list } };
			handleInputChange(e);
		} else {
			setShowWarningWD(true);
			setStringWarnAddWD(t("FORMS.attributeWD"));
			setTimeout(() => {
				setShowWarningWD(false);
			}, 3000);
		}
	};

	return (
		<MDBox display="flex" flex="1">
			<div style={{ borderLeft: "1px solid #ccc", height: "100%", margin: "0 10px" }} />
			<MDBox display="flex" flexDirection="column" width="100%">
				<MDTypography variant="h6">{t("FORMS.addDynamic")}</MDTypography>
				<FormControl sx={{ mt: 1 }}>
					<InputLabel id="select-label">{t("FORMS.apiName")}</InputLabel>
					<Select
						labelId="select-label"
						id="select"
						name="whitelistDynamic.apiName"
						value={values?.whitelistDynamic?.apiName || ""}
						onChange={updateDynamic}
					>
						{listAPIs
							?.sort((a, b) => a.name.localeCompare(b.name))
							?.map((api, index) => (
								<MenuItem key={index} value={api.name}>
									{api.name}
								</MenuItem>
							))}
					</Select>
				</FormControl>

				<FormControl sx={{ mt: 1, mb: 1 }}>
					<InputLabel id="select-label">{t("FORMS.actionAPI")}</InputLabel>
					<Select
						labelId="select-label"
						id="select"
						name="whitelistDynamic.action"
						value={values?.whitelistDynamic?.action || ""} /// Par défaut on laisse getRessourceWithQuery
						onChange={updateDynamic}
					>
						{Object.keys(
							listAPIs.find(item => item.name === values?.whitelistDynamic?.apiName)?.config
								?.action || {}
						).map((apiName, index) => (
							<MenuItem key={index} value={apiName}>
								{apiName}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<Accordion defaultExpanded className="customAccordion">
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						{t("FORMS.pramsAPI")}
					</AccordionSummary>
					<AccordionDetails>
						<MDInput
							sx={{ mt: 1 }}
							name="whitelistDynamic.params.attribute"
							className="dialogInput"
							label={t("FORMS.attributeAPI")}
							key="0"
							value={values?.whitelistDynamic?.params?.attribute || ""}
							disabled={!PI}
							onChange={updateDynamic}
						/>

						<Tooltip
							open={showWarningWD}
							title={stringWarnAddWD}
							placement="right"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={
									lod_.isArray(values?.whitelistDynamic?.params?.searchFields)
										? values?.whitelistDynamic?.params?.searchFields
										: []
								}
								onChange={(e, newValue) => {
									onChangeDynamicTag("whitelistDynamic.params.searchFields", newValue);
								}}
								sx={{ mt: 1 }}
								readOnly={!PI}
								multiple
								id="tags-filled"
								options={[]}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip label={option} {...getTagProps({ index })} key={index} />;
									});
								}}
								renderInput={params => <MDInput {...params} label={t("FORMS.searchFieldsAPI")} />}
							/>
						</Tooltip>
					</AccordionDetails>
				</Accordion>
			</MDBox>
		</MDBox>
	);
};

export default PannelDynamicList;
