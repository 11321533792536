import { Icon, IconButton } from "@mui/material";
import { t } from "i18next";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

/**
 * Template part for one message in the middle pannel
 * @param {*} param0
 * @returns
 */
const PromptMessageItem = ({ prompt, index, deletePrompt }) => {
	return (
		<>
			<MDBox display="flex" alignItems="center" justifyContent="space-between" flex="1">
				<MDBox display="flex" alignItems="center">
					<MDBox display="flex" alignItems="center" mr={1}>
						<Icon>texture</Icon>
					</MDBox>
					<MDTypography variant="h6">{prompt.name}</MDTypography>
				</MDBox>

				<IconButton
					color="dark"
					onClick={() => {
						deletePrompt(index);
					}}
				>
					<Icon>delete</Icon>
				</IconButton>
			</MDBox>
			<MDTypography variant="body2">
				{prompt.description || t("PROMPT.PROCESS.EDIT.TEMPLATES.noDescription")}
			</MDTypography>
		</>
	);
};

export default PromptMessageItem;
