import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

import { MESSAGE_TYPE } from "../../../constants";

/**
 * View complete prompt, display all messages type and message content
 * @returns
 */
const PromptPreview = ({ message, promptTemplates }) => {
	// Map the prompt parts to the correct prompt
	let mappedPreview = message.promptParts.map(part => {
		let prompt = promptTemplates.find(prompt => prompt.code === part);
		if (prompt) {
			return prompt.prompt;
		}
		return "";
	});
	// Find the correct type of message
	let correctType = MESSAGE_TYPE.find(type => type.code === message.type);
	return (
		<MDBox display="flex" justifyContent="end">
			<MDBox p={2} borderRadius="xl" bgColor="light" mb={2} width="80%">
				<MDTypography variant="h6" sx={{ mb: 2 }} align="right">
					{correctType.label}
				</MDTypography>
				<MDTypography
					variant="body2"
					fontSize="medium"
					color="textSecondary"
					style={{ whiteSpace: "pre-wrap" }}
				>
					{mappedPreview.join(" ")}
				</MDTypography>
			</MDBox>
		</MDBox>
	);
};

export default PromptPreview;
