import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	disableRouting: false,
	changeRoute: false
};

export const error = initialState;

const slice = createSlice({
	name: "application",
	initialState,
	reducers: {
		setDisableRouting: (state, action) => {
			state.disableRouting = true;
		},
		setEnableRouting: (state, action) => {
			state.disableRouting = false;
		},
		changeRouteHandle: (state, action) => {
			state.changeRoute = action.payload;
		}
	}
});

export const isRoutingDisabled = state => state.application.disableRouting;

export const { setDisableRouting, setEnableRouting, changeRouteHandle } = slice.actions;

export default slice.reducer;
