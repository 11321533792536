/* eslint-disable no-plusplus */
import lod_ from "lodash";

/**
 * Get the path's label for a dictionary
 * @param {*} dictionary
 * @param {*} path
 * @returns
 */
const getPath = (dictionary, path) => {
	if (!path) return "Non défini";

	const parts = path.split(".");
	const result = [];
	let current = "";

	for (let i = 0; i < parts.length; i++) {
		current += parts[i];
		result.push(current);
		current += ".items.";
	}

	let stringPath = "";

	for (let i = 0; i < result.length; i++) {
		stringPath += " " + lod_.get(dictionary, result[i] + ".label.fr") + " /" || "";
	}

	stringPath = stringPath.slice(0, -1);
	return stringPath;
};

// A custom component to represent a variable inside the input
const FaibrikVariable = ({ path = "", dictionary = {} }) => {
	return (
		<span
			data-attribute="advanced-input-component-variable"
			className="fAIbrik-variable"
			contentEditable="false"
			data-path={path}
			style={{
				backgroundColor: "#007bff",
				color: "#fff"
			}}
		>
			{getPath(dictionary, path)}
		</span>
	);
};

export default FaibrikVariable;
