import { Fade, Grow } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { useEffect } from "react";
import C from "../constants";
import InformationCard from "../components/InformationCard";
/**
 * Step 1: Selected the chart type
 */
const ChartChoiceStep = ({ chart, handleSelectChart, validStep, handleNext }) => {
	// Handle select chart
	const selectChart = chart => {
		handleSelectChart(chart);
		handleNext();
		validStep();
	};

	useEffect(() => {
		if (chart?.type) {
			validStep();
		}
	}, []);

	return (
		<MDBox>
			<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
				{C.CHARTS_TYPE.map((c, index) => {
					return (
						<Grow key={index} in timeout={(index + 1) * 500}>
							<div>
								<InformationCard
									element={c}
									onClick={() => selectChart(c)}
									selected={chart && chart.type === c.type}
								/>
							</div>
						</Grow>
					);
				})}
			</MDBox>
		</MDBox>
	);
};

export default ChartChoiceStep;
