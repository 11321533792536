import { Icon, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

/**
 * Display a tag for a channel in the autocomplete
 * @returns
 */
const ChannelTag = ({ option, getTagProps, index }) => {
	return (
		<MDBox bgColor="light" borderRadius="md" p={1} mr={1}>
			<Tooltip placement="top" title={option.name || option.description || ""}>
				<MDBox display="flex" alignItems="center">
					{option.logoURL && (
						<MDBox
							component="img"
							src={option.logoURL}
							alt={option.name}
							style={{
								width: "30px",
								height: "30px",
								borderRadius: "5px",
								objectFit: "contain"
							}}
						/>
					)}
					{option.icon && (
						<Icon
							fontSize="medium"
							sx={{
								color: "white.dark"
								// width: "40px",
								// height: "40px"
							}}
						>
							{option.icon}
						</Icon>
					)}

					{/* <MDTypography color="white" sx={{ ml: 0.5 }} variant="caption" fontWeight="bold">
					{option.name}
				</MDTypography> */}

					<IconButton size="small" onClick={e => getTagProps({ index }).onDelete(e)}>
						<Icon
							sx={{ ml: 1 }}
							style={{
								color: "dark"
							}}
						>
							close
						</Icon>
					</IconButton>
				</MDBox>
			</Tooltip>
		</MDBox>
	);
};

export default ChannelTag;
