/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef, createContext, useContext, useMemo } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";

// Custom styles for MDPagination
import MDPaginationItemRoot from "components/Basics/MDPagination/MDPaginationItemRoot";

// The Pagination main context
const Context = createContext(null);

const MDPagination = forwardRef(
	(
		{
			item = false,
			variant = "gradient",
			color = "info",
			size = "medium",
			active = false,
			children = false,
			...rest
		},
		ref
	) => {
		const context = useContext(Context);
		const paginationSize = context ? context.size : null;

		const value = useMemo(() => ({ variant, color, size }), [variant, color, size]);

		return (
			<Context.Provider value={value}>
				{item ? (
					<MDPaginationItemRoot
						{...rest}
						ref={ref}
						variant={active ? context.variant : "outlined"}
						color={active ? context.color : "secondary"}
						iconOnly
						circular
						ownerState={{ variant, active, paginationSize }}
					>
						{children}
					</MDPaginationItemRoot>
				) : (
					<MDBox
						display="flex"
						justifyContent="flex-end"
						alignItems="center"
						sx={{ listStyle: "none" }}
					>
						{children}
					</MDBox>
				)}
			</Context.Provider>
		);
	}
);

export default MDPagination;
