/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Filter settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Icon,
	IconButton
} from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import DatePresets from "components/Custom/DatePresets";
import i18n from "i18n";
import lod_ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { generateNameCode } from "helpers/utilities";
import { display } from "redux-react/reducers/snackBarReducer";
import SettingsActions from "redux-react/actions/settingsActions";
import DictionaryMenu from "./DictionaryMenu";

const FilterRow = ({
	type,
	filter,
	dictionary,
	value = "",
	linkItem,
	removeLink,
	removeItem,
	onEdit
}) => {
	const [dictionaryAnchorEl, setDictionaryAnchorEl] = useState(null);

	const COLLECTIONS_SOURCE_MAP = {
		custom: i18n.t("SETTINGS.CHARTS.dictionariesDatas"),
		dataSources: i18n.t("SETTINGS.CHARTS.faibrikDatas")
	};

	let itemCollection = dictionary[filter.dictionaryPath.split(".")[0]];
	let collectionName = itemCollection.label.fr;
	let collectionSource = COLLECTIONS_SOURCE_MAP[itemCollection.source];

	const [defaultValue, setDefaultValue] = useState(value);
	/**
	 * Display name of dictionary element
	 */
	const getLabel = item => {
		return lod_.get(dictionary, item.dictionaryPath.replaceAll(".", ".items.")).label.fr;
	};

	/**
	 * Get the path's label for a dictionary
	 * @param {*} dictionary
	 * @param {*} path
	 * @returns
	 */
	const getPath = path => {
		if (!path) {
			return "None";
		}

		const parts = path.split(".");
		const result = [];
		let current = "";

		for (let i = 0; i < parts.length; i++) {
			current += parts[i];
			result.push(current);
			current += ".items.";
		}

		let stringPath = "";

		for (let i = 0; i < result.length; i++) {
			stringPath += " " + lod_.get(dictionary, result[i] + ".label.fr") + " /" || "";
		}

		stringPath = stringPath.slice(0, -1);
		return stringPath;
	};

	return (
		<MDBox mt={1.5} bgColor="light" borderRadius="lg" shadow="lg" opacity={1} p={1}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between">
				<MDBox>
					<MDTypography variant="overline">{`${collectionSource}: ${collectionName} - ${filter.path.replaceAll(
						".",
						"/"
					)}`}</MDTypography>
					<MDTypography variant="h6">{getLabel(filter)}</MDTypography>
				</MDBox>

				<MDBox>
					<IconButton
						onClick={e => {
							setDictionaryAnchorEl(e.target);
						}}
					>
						<Icon fontSize="medium">link</Icon>
					</IconButton>
					<DictionaryMenu
						dictionary={dictionary}
						anchorEl={dictionaryAnchorEl}
						handleInsertText={e => {
							if (e !== filter.dictionaryPath) {
								linkItem(filter, e);
							}
							setDictionaryAnchorEl(null);
						}}
						handleClose={() => setDictionaryAnchorEl(null)}
					/>

					<IconButton
						onClick={() => {
							removeItem(filter);
						}}
					>
						<Icon fontSize="medium">close</Icon>
					</IconButton>
				</MDBox>
			</MDBox>
			{type.includes("date") && (
				<>
					<Divider />
					<MDBox display="flex" alignItems="center" justifyContent="space-between">
						<MDBox
							flex="1"
							mr={1}
							style={{
								whiteSpace: "nowrap"
							}}
						>
							<MDTypography variant="caption">
								{i18n.t("FILTERS.date.PRESETS.defaultValue")}
							</MDTypography>
						</MDBox>
						<MDInput
							flex="1"
							fullWidth
							disabled
							size="small"
							value={defaultValue ? i18n.t(`FILTERS.date.PRESETS.${defaultValue}`) : ""}
						/>
						<MDBox flex="1" ml={1}>
							<DatePresets
								fullHeight
								handleSelectPreset={({ interval, code }) => {
									setDefaultValue(code);
									onEdit({
										defaultValue: code
									});
								}}
							/>
						</MDBox>
					</MDBox>
				</>
			)}
			{filter.links?.length > 0 && (
				<MDBox mt={2}>
					<MDTypography variant="caption">Champs liés :</MDTypography>
					{(filter.links ?? []).map((link, index) => {
						return (
							<MDBox
								key={index}
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="space-between"
								className="link-row-filter"
								p={0.5}
								borderRadius="md"
							>
								<MDTypography
									variant="body2"
									fontSize="small"
									style={{
										fontWeight: "bold"
									}}
								>
									{getPath(link.dictionaryPath)}
								</MDTypography>
								<IconButton onClick={() => removeLink(filter.dictionaryPath, link)}>
									<Icon>delete</Icon>
								</IconButton>
							</MDBox>
						);
					})}
				</MDBox>
			)}
		</MDBox>
	);
};

const SpecialFilterRow = ({ filter, removeItem }) => {
	return (
		<MDBox mt={1.5} bgColor="light" borderRadius="lg" shadow="lg" opacity={1} p={1}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between">
				<MDBox>
					<MDTypography variant="overline">{i18n.t("FILTERS.basicFieldTitle")}</MDTypography>
					<MDTypography variant="h6">{filter.label}</MDTypography>
				</MDBox>

				<IconButton
					onClick={() => {
						removeItem(filter);
					}}
				>
					<Icon fontSize="medium">close</Icon>
				</IconButton>
			</MDBox>
		</MDBox>
	);
};

export default function FilterDialog({
	open,
	handleCloseDialog,
	handleSave,
	dictionary: clientDictionary,
	filter,
	edit = false
}) {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);

	const SPECIAL_FILTERS = {
		basicFieldsTitle: {
			source: "title",
			label: i18n.t("FILTERS.basicFieldsTitle")
		},
		specialDate: {
			source: "special",
			code: "date",
			label: {
				fr: i18n.t("FILTERS.specialDate")
			},
			type: "datetime"
		},
		dictionaryFieldsTitle: {
			source: "title",
			label: i18n.t("FILTERS.dictionaryFieldsTitle")
		}
	};

	const [dictionary, setDictionary] = useState({
		...SPECIAL_FILTERS,
		...clientDictionary
	});

	const [name, setName] = useState(null);
	const [filters, setFilters] = useState([]);

	const [anchorEl, setAnchorEl] = useState(null);

	const isDisabled = !name || filters.length < 1;
	/**
	 * Close dialog
	 */
	function close() {
		if (anchorEl) {
			setAnchorEl(null);
		} else {
			handleCloseDialog();
			setName(null);
			setFilters([]);
		}
	}
	/**
	 * Save filter
	 */
	function submit() {
		let generatedCode = generateNameCode(name);

		let newFilter = {
			name,
			filters,
			code: edit ? filter.code : generatedCode,
			active: true
		};

		const onSuccessEdit = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.FILTERS.SUCCESS.edit"),
					type: "success"
				})
			);
			handleSave();
			close();
		};

		const onSuccessAdd = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.FILTERS.SUCCESS.add"),
					type: "success"
				})
			);
			handleSave();
			close();
		};

		if (edit) {
			dispatch(
				SettingsActions.editSetting(profile.assistantID, "filters", newFilter, onSuccessEdit)
			);
		} else {
			dispatch(SettingsActions.newSetting(profile.assistantID, "filters", newFilter, onSuccessAdd));
		}
	}
	/**
	 * Remove filter from filters list
	 */
	function removeItem(item) {
		setFilters(filters.filter(f => f.path !== item.path));
	}
	/**
	 * Add filter to filters list
	 */
	const addItem = item => {
		let sourcePath = item.split(".")[0];
		let sourceObject = lod_.get(dictionary, sourcePath)?.source;
		let itemLabel = lod_.get(dictionary, item.replaceAll(".", ".items."))?.label?.fr;
		let dictionaryPath = lod_.clone(item);
		let allObject = lod_.get(dictionary, sourcePath);

		switch (sourceObject) {
			case "custom":
			case "dataSources":
				// If item is from dataSources, we need to use root path (si remove first part of path)
				item = item.split(".").slice(1).join(".");
				break;
			case "assistantConfig":
				// If item is from assistantConfig, we need to use dictionary path
				item = "dictionary." + item;
				break;
			default:
				break;
		}

		setAnchorEl(null);
		let same = filters.find(f => f.dictionaryPath === dictionaryPath);
		if (!same) {
			let filter = {
				path: item,
				dictionaryPath,
				source: sourceObject,
				label: itemLabel
			};

			if (allObject.source === "special") {
				filter.type = allObject.type;
			}

			setFilters([...filters, filter]);
		}
	};

	const editItem = (path, item) => {
		setFilters(filters => {
			let filter = filters.find(f => f.dictionaryPath === path);
			filter = { ...filter, ...item };
			return filters.map(f => (f.dictionaryPath === path ? filter : f));
		});
	};

	const removeLink = (path, link) => {
		let clonedFilters = lod_.cloneDeep(filters);
		let filterIndex = clonedFilters.findIndex(f => f.dictionaryPath === path);
		let filter = clonedFilters[filterIndex];
		let linkIndex = filter.links.findIndex(l => l.dictionaryPath === link.dictionaryPath);
		filter.links.splice(linkIndex, 1);

		clonedFilters[filterIndex] = filter;
		setFilters(clonedFilters);
	};

	const linkItem = (filter, path) => {
		let sourcePath = path.split(".")[0];
		let sourceObject = lod_.get(dictionary, sourcePath)?.source;
		let itemLabel = lod_.get(dictionary, path.replaceAll(".", ".items."))?.label?.fr;
		let dictionaryPath = lod_.clone(path);

		switch (sourceObject) {
			case "custom":
			case "dataSources":
				// If item is from dataSources, we need to use root path (si remove first part of path)
				path = path.split(".").slice(1).join(".");
				break;
			case "assistantConfig":
				// If item is from assistantConfig, we need to use dictionary path
				path = "dictionary." + path;
				break;
			default:
				break;
		}

		let link = {
			path,
			dictionaryPath,
			source: sourceObject,
			label: itemLabel
		};

		if (!filter.links) {
			filter.links = [];
		}

		let same = filter.links.find(l => l.dictionaryPath === dictionaryPath);
		if (!same) {
			filter.links.push(link);
		}

		let filterIndex = filters.findIndex(f => f.dictionaryPath === filter.dictionaryPath);

		setFilters(filters => {
			filters[filterIndex] = filter;
			return filters;
		});
	};

	const checkFilterValidity = filter => {
		if (!filter.path || !filter.label || !filter.dictionaryPath) return false;
		let itemDic = lod_.get(dictionary, filter.dictionaryPath.replaceAll(".", ".items."));
		if (!itemDic) return false;
		return true;
	};

	useEffect(() => {
		setName(filter.name ?? "");
		setFilters(filter.filters ?? []);
	}, [filter]);
	/**
	 * Main component
	 */
	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={open}
			onClose={close}
			PaperProps={{
				style: {
					height: "80%",
					width: "100%",
					maxWidth: "80%"
				}
			}}
		>
			<DialogTitle>
				{edit ? i18n.t("SETTINGS.FILTERS.edit") : i18n.t("SETTINGS.FILTERS.add")}
			</DialogTitle>
			<DialogContent>
				<MDBox mt={1}>
					<MDInput
						value={name}
						className="dialogInput"
						label={i18n.t("SETTINGS.FILTERS.name")}
						onChange={e => setName(e.target.value)}
					/>
				</MDBox>

				<MDBox mt={2} display="flex" alignItems="center" justifyContent="space-between">
					<MDTypography variant="h6">{i18n.t("SETTINGS.FILTERS.items")}</MDTypography>

					<MDButton variant="gradient" color="info" onClick={e => setAnchorEl(e.target)}>
						{i18n.t("SETTINGS.add")}
					</MDButton>
					<DictionaryMenu
						dictionary={dictionary}
						anchorEl={anchorEl}
						handleInsertText={addItem}
						handleClose={() => setAnchorEl(null)}
					/>
				</MDBox>

				{!filters.length && (
					<MDBox mt={1} display="flex" justifyContent="center">
						<MDTypography variant="overline">{i18n.t("SETTINGS.FILTERS.noItems")}</MDTypography>
					</MDBox>
				)}

				{filters.length > 0 && (
					<>
						{filters.map((filter, index) => {
							let sourceObject = lod_.get(
								dictionary,
								filter.dictionaryPath.replaceAll(".", ".items.")
							);
							if (filter.source === "special") {
								return <SpecialFilterRow key={index} filter={filter} removeItem={removeItem} />;
							}
							let valid = checkFilterValidity(filter);
							if (!valid) {
								return (
									<MDBox
										key={index}
										mt={1.5}
										bgColor="error"
										borderRadius="lg"
										shadow="lg"
										opacity={1}
										p={1}
										display="flex"
										alignItems="center"
										justifyContent="space-between"
									>
										<MDBox>
											<MDTypography variant="h6">{`⚠️ Broken filter (${
												filter.label ?? filter.path
											})`}</MDTypography>
										</MDBox>

										<IconButton
											onClick={() => {
												removeItem(filter);
											}}
										>
											<Icon fontSize="medium">close</Icon>
										</IconButton>
									</MDBox>
								);
							}
							return (
								<FilterRow
									key={index}
									filter={filter}
									dictionary={dictionary}
									removeItem={removeItem}
									type={sourceObject.type}
									value={filter.defaultValue}
									onEdit={item => {
										editItem(filter.dictionaryPath, item);
									}}
									linkItem={linkItem}
									removeLink={removeLink}
								/>
							);
						})}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={isDisabled} variant="contained" color="info" onClick={submit}>
					{edit ? i18n.t("SETTINGS.edit") : i18n.t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
